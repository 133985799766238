import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import content from "../views/content.vue";
import newlist from "../views/newlist.vue";
import newDetail from "../views/newDetail.vue";
import examplelist from "../views/examplelist.vue";
import exampleDetail from "../views/exampleDetail.vue";
import history from "../views/history.vue";
import honor from "../views/honor.vue";
import certification from "../views/certification.vue";
import organization from "../views/organization.vue";
import contact from "../views/contact.vue";
import job from "../views/job.vue";
import business from "../views/business.vue";
import Layout from "@/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "首页",
        component: index,
      },
      {
        path: "/content_:id/:childId",
        name: "详情",
        component: content,
      },
      {
        path: "/newList_:id/:childId",
        name: "新闻列表",
        component: newlist,
      },
      {
        path: "/newDetail_:id/:childId/:newId",
        name: "新闻详情",
        component: newDetail,
      },
      {
        path: "/examplelist_:id",
        name: "案例列表",
        component: examplelist,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/exampleDetail_:id/:newId",
        name: "案例详情",
        component: exampleDetail,
      },
      {
        path: "/history_:id/:childId",
        name: "发展历程",
        component: history,
      },
      {
        path: "/honor_:id/:childId",
        name: "公司荣誉",
        component: honor,
      },
      {
        path: "/certification_:id/:childId",
        name: "合作资质",
        component: certification,
      },
      {
        path: "/organization_:id/:childId",
        name: "组织架构",
        component: organization,
      },
      {
        path: "/contact_:id",
        name: "联系我们",
        component: contact,
      },
      {
        path: "/job_:id",
        name: "招贤纳士",
        component: job,
      },
      {
        path: "/business_:id/:childId",
        name: "业务介绍",
        component: business,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;

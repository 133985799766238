// 字典仅有一级 组件
<template>
  <div class="justOne">
    <div class="top">
      <div class="title">{{ item.name | handleShowName }}</div>
      <span class="ask" @click="toBottom">免费咨询</span>
    </div>
    <div class="center">{{ item.contextStrs }}</div>
    <div class="bottom">
      <div class="desc" v-if="!isMobile" v-html="content.contentStr"></div>
      <div
        class="desc"
        v-else
        v-html="
          content.mobileContentStr
            ? content.mobileContentStr
            : content.contentStr
        "
      ></div>
    </div>
  </div>
</template>

<script>
import { URL } from "../../util/config";
import { Store } from "../../store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      item: [],
      content: "",
      URL,
      imgLeft: "",
      imgRight: "",
    };
  },
  watch: {
    list(newval) {
      this.item = { ...newval };
      this.content = { ...newval.sysDictionaryNewVos[0].sysNew[0] };

      this.deal();
    },
  },
  props: ["list"],
  created() {
    this.item = { ...this.list };
    this.content = { ...this.list.sysDictionaryNewVos[0].sysNew[0] };

    this.deal();
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
  },
  mounted() {},
  methods: {
    deal() {
      let res = this.content.newsImageUrl.indexOf(",") != -1;
      let arr = this.content.newsImageUrl.split(",");
      this.imgLeft = res ? arr[0] : this.content.newsImageUrl;
      this.imgRight = res ? arr[1] : "";
    },
    toBottom() {
      this.$emit("scrollTo");
    },
  },
};
</script>

<style lang="scss" scoped>
.justOne {
  width: 1000px;
  margin: 0 auto 70px;
  color: rgb(125, 126, 129);

  .top {
    width: fit-content;
    position: relative;
    .title {
      font-size: 27px;
      font-weight: 400;
      padding: 10px 0;
      position: relative;
      color: #000;

      &::after {
        content: "";
        display: block;
        width: 50%;
        height: 3px;
        background-color: $theme;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
    .ask {
      white-space: nowrap;
      font-size: 18px;
      color: #fff;
      padding: 2px 10px;
      border-radius: 3px;
      background-color: $theme;
      position: absolute;
      left: 120%;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        cursor: pointer;
      }
    }
  }
  .center {
    margin: 20px 0;
    text-align: center;
  }

  .bottom {
    .desc {
      color: #444;
      font-size: 16px;
      padding: 40px 20px 20px;
      box-sizing: border-box;
      letter-spacing: 0.5px;

      p {
        text-align: justify;
      }
    }
  }
}
@media (max-width: 1300px) {
  .justOne {
    width: 100%;
  }
}
@media (max-width: 750px) {
  .justOne {
    margin-bottom: 20px;

    .top {
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .ask {
        font-size: 16px;
      }
    }
    .bottom {
      .desc {
        padding-top: 20px;
      }
    }
  }
}
</style>

<template>
  <div class="layout">
    <Top
      :isPlaceHolder="holderPath"
      :isShowbgcolor="isShowbgcolor"
      @mouseevent="changeTopBg"
    />
    <el-backtop :visibility-height="400" @click="backTotop"></el-backtop>
    <keep-alive>
      <router-view
        :key="$route.path"
        v-if="$route.meta.keepAlive"
      ></router-view>
    </keep-alive>
    <router-view :key="$route.path" v-if="!$route.meta.keepAlive"></router-view>
    <Foot />
  </div>
</template>

<script>
import Top from "./components/top.vue";
import Foot from "./components/bottom.vue";
import { _isMobile } from "../util/tool";

export default {
  data() {
    return {
      isShowbgcolor: false,
    };
  },
  components: {
    Top,
    Foot,
  },
  computed: {
    holderPath() {
      return this.$route.path != "/";
    },
  },
  watch: {
    $route(to) {
      // 路由跳转时判断
      this.isListen(to);
    },
  },
  created() {},
  mounted() {
    this.isShowbgcolor = this.$route.path != "/";
    // 首次进入时判断一次
    this.isListen(this.$route);
    const nowIsMobile = _isMobile();
    window.addEventListener("resize", () => {
      if (nowIsMobile != _isMobile()) {
        window.location.reload();
      }
    });
  },
  methods: {
    // 判断是否需要监听top的变化
    isListen(to) {
      if (to.path == "/") {
        // 首页才需要监听top的变化
        window.addEventListener("scroll", this.listenScroll);
      } else {
        window.removeEventListener("scroll", this.listenScroll);
      }
    },
    // 监听事件
    listenScroll() {
      let top = document.documentElement.scrollTop;
      this.isShowbgcolor = top > 72;
    },
    backTotop() {
      window.scrollTo(0, 0);
    },
    changeTopBg(bol) {
      if (this.$route.path != "/") return;
      this.isShowbgcolor = bol;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-backtop {
  color: $theme !important;
}
@media (max-width: 1200px) {
  .el-backtop {
    bottom: 1.8rem !important;
  }
}
</style>

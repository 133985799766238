<!-- 初试进入等待框 -->
<template>
  <div class="load_box">
    <img
      class="prev_logo"
      src="@/assets/index/loading_logo.png"
      alt="盘古广告logo"
      title="盘古广告"
    />
    <div class="progress_box">
      <img
        class="next_logo"
        src="@/assets/index/loading_logo.png"
        alt="盘古广告logo"
        title="盘古广告"
      />
      <div class="progress" :style="{ width: dealGress }"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    reqCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dealGress() {
      return 100 - this.reqCount * 33 + "%";
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.load_box {
  width: 250px;
  height: 92px;
  position: relative;

  img {
    width: 250px;
  }
  .progress_box {
    width: 250px;
    height: 92px;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    text-align: right;

    .next_logo {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .progress {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transition: width 1.5s;
    }
  }
}

@media (max-width: 1300px) {
  .load_box {
    width: 3rem;
    height: 92px;
    position: relative;

    img {
      width: 3rem;
    }
    .progress_box {
      width: 3rem;
    }
  }
}
</style>

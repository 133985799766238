<template>
  <footer class="footer">
    <div class="footer-top" v-if="!isMobile">
      <div class="footer-top-left">
        <img src="@/assets/footer-logo.png" alt="" class="logo-img" />
        <div class="footer-friend">
          <div class="label">公司地址：</div>
          <div class="list">
            <div class="friend" v-for="item in companyLink" :key="item.id">
              <a :href="item.hrefUrl" ref="nofollow" target="_blank">{{
                item.name
              }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-top-right">
        <div class="codes">
          <div class="wechaticon">
            <img style="width: 150px" :src="URL + globalConfig.wechatImgUrl" />
            <img class="wechaticon-out" src="@/assets/wechat_out.png" />
            <img class="wechaticon-on" src="@/assets/wechat_on.png" />
          </div>
          <div class="weiboicon">
            <img style="width: 150px" :src="URL + globalConfig.weboImgUrl" />
            <img class="weiboicon-out" src="@/assets/weibo_out.png" />
            <img class="weiboicon-on" src="@/assets/weibo_on.png" />
          </div>
        </div>
        <div class="right-info">
          <div>
            <img src="@/assets/phone.png" />
            联系电话：400-6222-028
          </div>
        </div>
      </div>
    </div>
    <div class="m-cj-friendlink" v-if="isMobile">
      <p class="contact">联系我们</p>
      <div class="list">
        <div class="cj-list-item" v-for="item in companyLink" :key="item.id">
          <a :href="item.hrefUrl" ref="nofollow" target="_blank">{{
            item.name
          }}</a>
        </div>
      </div>
      <div class="cj-contact">
        <div>联系电话：400-6222-028</div>
        <!-- <div>投诉电话：400-6222-028</div> -->
      </div>
    </div>
    <div :class="!isMobile ? 'cj-record' : 'm-cj-recode'">
      {{ globalConfig.copyRightMsg }} <br v-show="isMobile" />
      <a
        href="https://beian.mps.gov.cn/#/query/webSearch?code=51010402001570"
        target="_blank"
        rel="noreferrer nofollow"
        >川公网安备51010402001570</a
      >
      <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">{{
        handleIcp
      }}</a>
    </div>
    <tabbar v-if="isMobile" />
  </footer>
</template>
<script>
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
import { URL } from "@/util/config.js";
import tabbar from "../../components/tabbar.vue";
export default {
  components: { tabbar },
  data() {
    return {
      activeId: 0,
      URL: URL,
      domainArr: [
        {
          url: "pangodhd.com",
          icp: "蜀ICP备20011262号-2",
        },
        {
          url: "pangodbj.com",
          icp: "蜀ICP备18019436号-2",
        },
        {
          url: "pangodsx.com",
          icp: "蜀ICP备20011262号-2",
        },
        {
          url: "pangodss.com",
          icp: "蜀ICP备20011262号-2",
        },
        {
          url: "pangodmf.com",
          icp: "蜀ICP备20011262号-2",
        },
      ],
    };
  },
  computed: {
    ...mapState(Store, ["companyLink", "globalConfig", "isMobile"]),
    handleIcp() {
      const domain = this.$route.query.domain;
      if (!domain) {
        return this.globalConfig.icpMsg;
      }
      const nowDomain = this.domainArr.find((ele) => ele.url === domain);
      return nowDomain ? nowDomain.icp : this.globalConfig.icpMsg;
    },
  },
  mounted() {},
  methods: {
    changeNav(val) {
      this.activeId = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  background-color: #fafafa;

  .footer-top {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    padding: 20px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .footer-top-left {
      flex-grow: 1;
      height: 100%;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 10px;

      .logo-img {
        width: 200px;
        vertical-align: top;
      }

      .footer-friend {
        margin-top: 30px;

        .label {
          color: #3c79f3;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;

          .friend {
            width: 50%;
            margin-bottom: 5px;

            a {
              color: #666666;

              &:hover {
                color: $theme;
              }
            }
          }
        }
      }
    }
    .footer-top-right {
      user-select: text;
      text-align: center;
      flex-shrink: 0;

      div {
        font-size: 16px;
        color: #666666;
        img {
          width: 30px;
          vertical-align: middle;
        }
      }

      .right-info {
        margin-top: 20px;
      }
    }
    .codes {
      display: flex;

      .wechaticon {
        width: 150px;
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .wechaticon-out {
          width: 30px;
        }
        .wechaticon-on {
          width: 30px;
          display: none;
        }
        &:hover {
          .wechaticon-out {
            display: none;
          }
          .wechaticon-on {
            display: block;
          }
        }
      }
      .weiboicon {
        margin-left: 30px;
        width: 150px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .weiboicon-out {
          width: 30px;
          display: inline-block;
        }
        .weiboicon-on {
          width: 30px;
          display: none;
        }
        &:hover {
          .weiboicon-out {
            display: none;
          }
          .weiboicon-on {
            display: inline-block;
          }
        }
      }
    }
  }
  .cj-record {
    text-align: center;
    line-height: 40px;
    font-size: 13px;
    border-top: 1px solid #e4e4e4;
    color: #777777;

    a {
      margin: 0 5px;
      color: #777777;
    }
  }
}

@media (max-width: 1300px) {
  .footer {
    .footer-top {
      width: 1000px;
    }
  }
  .footer .footer-top .codes {
    .wechaticon,
    .weiboicon {
      width: 100px;

      img:first-child {
        width: 100px !important;
      }
    }
  }
}
@media (max-width: 1200px) {
  .footer {
    .footer-top {
      width: 100%;
      padding: 20px 20px 10px;
      box-sizing: border-box;
    }
  }
}

.footer .m-cj-friendlink {
  line-height: 30px;
  width: 100% !important;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  line-height: normal;

  .address,
  .contact {
    font-size: 0.3rem;
    line-height: 30px;
    margin-bottom: 0;
  }
  .contact {
    color: #333;
    font-weight: bold;
  }
  .cj-list-item {
    width: 100%;
    float: none;
    padding: 0;

    a {
      color: grey;
      padding: 5px;
      font-size: 0.2rem;
    }
  }
  .cj-contact {
    font-size: 0.2rem;
    color: grey;

    div {
      line-height: 22px;
    }
  }

  span {
    margin-right: 30px;
  }
}
.m-cj-recode {
  width: 90%;
  padding: 10px 5%;
  margin-bottom: 50px;
  background-color: #f5f5f5;
  text-align: center;
  line-height: 20px;
  font-size: 0.2rem;
  color: #777777;

  a {
    color: #777777;
    margin: 0 5px;
  }
}
</style>

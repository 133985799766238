<template>
  <div class="breadCrumb-box">
    <div class="box">
      <i class="el-icon-location-outline"></i>
      <span>您当前的位置: </span>
    </div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="item.path || ''"
        v-for="(item, idx) in routeArr"
        :key="idx"
        >{{ item.name || item }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["routeArr"],
  created() {},
  mounted() {},
  methods: {
    push(idx) {
      if (idx == 0) {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.breadCrumb-box {
  width: 1500px;
  margin: 20px auto;
  font-size: 14px;
  line-height: 23px;
  display: flex;
  align-items: center;

  .box {
    margin-right: 5px;
  }

  i {
    line-height: 23px;
    margin-right: 3px;
  }
}
@media (max-width: 1600px) {
  .breadCrumb-box {
    width: 1200px;
    margin: 10px auto;
  }
}
@media (max-width: 1300px) {
  .breadCrumb-box {
    width: 1000px;
  }
}
@media (max-width: 750px) {
  .breadCrumb-box {
    width: 100%;
  }
}
</style>

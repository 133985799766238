<template>
  <div class="pangod-classify">
    <!-- 顶部 -->
    <BreadCrumb :routeArr="routeArr" v-show="!isMobile"></BreadCrumb>

    <!-- 二级导航栏 -->
    <TypeNavs
      :activeCode="activeCode"
      :tabNavList="tabNavList"
      @changeNav="changeNav"
    />
    <!-- 内容 -->
    <div class="pangod-classify-content">
      <CustomLoading v-show="vanLoading" />
      <!-- 列表展示模式 -->
      <div class="new-box">
        <div
          class="new-item"
          v-for="item in newList"
          :key="item.id"
          @click="getInfo(item)"
        >
          <div class="left">
            <img :src="URL + item.newsImageUrl" />
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="create">
              {{ (item.publishTime || item.createTime) | handleTime }}
            </div>
            <div class="description" v-show="item.description">
              {{ item.description }}
            </div>
          </div>
        </div>

        <div class="empty" v-show="!vanLoading && newList.length == 0">
          <el-empty
            :image="require('../assets/empty.png')"
            description="暂无相关信息"
            :image-size="200"
          ></el-empty>
        </div>
      </div>
      <el-pagination
        class="pagination"
        :hide-on-single-page="newList.length < 12"
        @current-change="handleCurrentChange"
        :current-page="params.pageIndex"
        :page-size="params.pageSize"
        layout="total, prev, pager, next"
        :total="totalData"
        :small="isMobile"
      ></el-pagination>
    </div>

    <Tabbar v-if="isMobile"></Tabbar>
  </div>
</template>
<script>
import Tabbar from "@/components/tabbar.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import CustomLoading from "@/components/loading.vue";
import TypeNavs from "../components/typeNavs.vue";

import { Store } from "../store";
import { mapState } from "pinia";
import { URL } from "../util/config.js";
export default {
  data() {
    return {
      URL: URL,
      tabNavList: [],
      activeCode: "",
      newList: [],
      totalData: 0,
      params: {
        pageIndex: 1,
        pageSize: 12,
        newsCode: "",
      },
      routeArr: [],
      vanLoading: false,
      nowDic: {},
    };
  },
  components: {
    BreadCrumb,
    Tabbar,
    CustomLoading,
    TypeNavs,
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
  },
  created() {},
  mounted() {
    this.setPageConfig();
  },
  methods: {
    // 构建页面参数
    setPageConfig() {
      const query = this.$route.params;
      // 父子id
      const { id, childId } = query;
      out: for (const i in this.navList) {
        const ele = this.navList[i];
        if (ele.id == id) {
          // 获取二级分类
          this.tabNavList = ele.children;
          if (childId) {
            // 两个参数
            for (const j in ele.children) {
              const child = ele.children[j];
              // 获取激活字典
              if (child.id == childId) {
                this.nowDic = child;
                this.params.newsCode = child.code;
                this.getList();
                break out;
              }
            }
          } else {
            // 一级字典
            this.nowDic = ele;
            this.params.newsCode = ele.code;
            this.getList();
            break;
          }
        }
      }
      this.activeCode = this.nowDic.code;
      this.routeArr = ["首页", this.nowDic.name];
    },
    // 获取内容
    getList() {
      this.vanLoading = true;

      //获取列表
      this.$api
        .sysNewsList(this.params)
        .then((res) => {
          if (res.code === 0) {
            this.newList = res.data.list;
            this.totalData = res.data.total;
          }
        })
        .finally(() => {
          this.vanLoading = false;
        });
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.getList();
    },
    // 切换菜单
    changeNav(val) {
      this.$router.push(
        val.templateName + "_" + this.$route.params.id + "/" + val.id
      );
    },
    getInfo(item) {
      const id = this.$route.params.id;
      const childId = this.nowDic.id;
      this.$router.push(`/newDetail_${id}/${childId}/${item.id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.pangod-classify {
  font-size: 16px;
  background-color: #eeeef0;

  &::before {
    content: "";
    display: table;
  }

  .pangod-classify-banner {
    position: relative;
    width: 100%;
    max-height: 300px;
    margin-top: 96px;
    overflow: hidden;

    img {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
    .pangod-classify-banner-title {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1440px;
      transform: translate(-50%, -50%);
      color: $default;
      font-size: 44px;
    }
  }
  .navBar {
    background: #eeeef0;
    .pangod-classify-nav {
      width: 1500px;
      background-color: #fff;
      line-height: 60px;
      margin: 0 auto;
      .pangod-classify-nav-ul {
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: center;
      }
      .pangod-classify-nav-li {
        padding: 0 20px;
        text-align: center;
        cursor: pointer;
      }
      .active {
        color: rgba(2, 69, 140, 0.7);
        position: relative;
        &::before {
          display: block;
          content: "";
          height: 3px;
          width: 50%;
          background: rgba(2, 69, 140, 0.7);
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          z-index: 2;
          opacity: 1;
        }
      }
    }
  }

  .pangod-classify-content {
    width: 1440px;
    margin: 0 auto 20px;
    padding: 30px;
    background-color: #ffffff;

    .van-loading {
      text-align: center;
    }

    .new-box {
      display: flex;
      flex-wrap: wrap;
    }
    .new-item {
      width: calc(25% - 22px);
      margin: 0 10px 20px;
      cursor: pointer;
      transition: all 0.3s;
      border-radius: 5px;
      overflow: hidden;

      .left {
        width: 100%;
        height: 200px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          transition: all 0.3s;
        }
      }
      .right {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        padding: 10px;

        &::after {
          content: "";
          display: block;
          clear: both;
        }
        .type {
          width: 50%;
          float: left;
          text-align: center;
          margin-top: 10px;

          p {
            font-size: 13px;
          }

          p:first-child {
            font-weight: bold;
            font-size: 14px;
            color: #02458c;
          }
        }
        .title {
          width: 100%;
          color: grey;
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .create {
          width: 100%;
          font-size: 14px;
          display: inline-block;
          color: #919797;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .description {
          width: 100%;
          font-size: 14px;
          color: #5f6464;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);

        img {
          transform: scale(1.05);
        }
      }
    }

    .empty {
      text-align: center;
    }
  }
}
@media (max-width: 1600px) {
  .pangod-classify .pangod-classify-content {
    padding: pxToRem(20);
    width: 1200px;
    box-sizing: border-box;
  }
  .pangod-classify .navBar .pangod-classify-nav {
    width: 1200px;
  }
  .pangod-classify-nav-li {
    font-size: 14px;
  }
}
@media (max-width: 1300px) {
  .pangod-classify {
    .navBar {
      .pangod-classify-nav {
        width: 1000px;
      }
    }

    .pangod-classify-content {
      width: 1000px;

      .new-item {
        width: calc(33% - 20px);
      }
    }
  }
}
@media (max-width: 1200px) {
  .pangod-classify .pangod-classify-content {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: pxToRem(10);
  }

  .pangod-classify {
    .navBar .pangod-classify-nav {
      width: 100%;
      line-height: 30px;
    }
  }
  .pangod-classify .navBar .pangod-classify-nav {
    width: 100%;
    line-height: 30px;

    .pangod-classify-nav-ul {
      justify-content: start;
      white-space: nowrap;
      overflow-x: scroll;
      padding: pxToRem(5) pxToRem(10);
    }
  }
}
@media (max-width: 750px) {
  .pangod-classify .navBar .pangod-classify-nav {
    line-height: pxToRem(40);

    .pangod-classify-nav-ul {
      font-size: pxToRem(15);
    }
  }
  .pangod-classify .pangod-classify-content {
    .new-item {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
</style>

<template>
  <div class="pangod-classify">
    <el-dialog
      width="500px"
      title="投递简历"
      :visible.sync="show"
      :before-close="beforeClose"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="岗位名称">
          <span>{{ nowJob.jobName }}</span>
        </el-form-item>
        <el-form-item label="附件简历">
          <el-upload
            drag
            :action="uploadURL"
            :on-success="uploadSuc"
            :on-remove="uploadRemove"
            :file-list="fileList"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>(格式为pdf、word等)
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="beforeClose">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="startSend"
          :loading="sending"
          >投 递</el-button
        >
      </span>
    </el-dialog>
    <!-- 顶部 -->
    <BreadCrumb :routeArr="routeArr" v-if="!isMobile"></BreadCrumb>

    <!-- 内容 -->
    <div class="pangod-classify-content">
      <CustomLoading v-show="vanLoading" />
      <div class="job-box">
        <div
          class="pangod-classify-content-jobitem"
          v-for="item in jobList"
          :key="item.id"
        >
          <h3>
            <img
              style="margin-right: 10px; vertical-align: top"
              src="@/assets/contactIcon2.png"
            />
            <span>{{ item.jobName }}</span>
            <span class="val" v-show="item.jobNum != 0"
              >招聘人数:{{ item.jobNum }}</span
            >
            <span class="val"
              >薪资:{{ item.moneyMin }} - {{ item.moneyMax }}</span
            >
            <el-button
              class="email"
              size="small"
              type="primary"
              @click="openShow(item)"
              v-if="item.jobEmail"
              >投递简历</el-button
            >
          </h3>
          <div class="info">
            <div class="left">
              <p class="description">{{ item.dutyContent }}</p>
            </div>
            <div class="right">
              <p class="description">{{ item.requireContent }}</p>
            </div>
          </div>
        </div>
        <div class="pangod-classify-content-jobitem">
          <h3 v-if="!isMobile">招聘联系电话：<b>19141990252</b></h3>
          <h3 v-else>
            招聘联系电话：<a href="tel:19141990252">19141990252</a>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/breadCrumb.vue";
import CustomLoading from "@/components/loading.vue";

import { URL, uploadURL } from "@/util/config.js";
import { Store } from "../store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      URL,
      uploadURL,
      fileList: [],
      jobList: [],
      show: false,
      nowJob: {},
      sendParams: {
        jobId: "",
        fileName: "",
      },
      sending: false,
      tabNavList: [],
      activeCode: "",
      params: {
        pageIndex: 1,
        pageSize: 12,
        newsCode: undefined,
        typeCode: undefined,
      },
      routeArr: [],
      vanLoading: false,
      detail: {},
    };
  },
  components: {
    BreadCrumb,
    CustomLoading,
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
    // 富文本手机端兼容mobileContentStr字段
    handleHTML() {
      const { contentStr, mobileContentStr } = this.detail;
      if (this.isMobile) {
        return mobileContentStr || contentStr;
      } else {
        return contentStr;
      }
    },
  },
  mounted() {
    // 从其他路由到classify路由 或 首次进入网页为classify时
    this.setPageConfig();
  },
  methods: {
    // 构建页面参数
    setPageConfig() {
      const query = this.$route.params;
      // 父子id
      const { id, childId } = query;
      let nowDic = {};
      out: for (const i in this.navList) {
        const ele = this.navList[i];
        if (ele.id == id) {
          // 获取二级分类
          this.tabNavList = ele.children;
          if (childId) {
            // 两个参数
            for (const j in ele.children) {
              const child = ele.children[j];
              // 获取激活字典
              if (child.id == childId) {
                nowDic = child;
                this.getList(nowDic);
                break out;
              }
            }
          } else {
            // 一级字典
            nowDic = ele;
            this.getList(nowDic);
            break;
          }
        }
      }
      this.routeArr = [{ name: "首页", path: "/" }, nowDic.name];
    },
    // 获取内容
    getList(val) {
      this.params.newsCode = val.code;

      this.vanLoading = true;

      //获取列表
      this.$api
        .sysJobList()
        .then((res) => {
          if (res.code === 0) {
            this.jobList = res.data;
          }
        })
        .finally(() => {
          this.vanLoading = false;
        });
    },
    // 切换菜单
    changeNav(val) {
      this.$router.push(
        val.templateName + "_" + this.$route.params.id + "/" + val.id
      );
    },
    openShow(item) {
      this.nowJob = item;
      this.show = true;
    },
    beforeClose(){
      this.fileList = [];
      this.show = false;
    },
    uploadSuc(res) {
      if (res.code != 0) {
        this.$notify.error(res.msg);
        this.fileList = [];
        return;
      }
      this.sendParams.fileName = res.data;
    },
    uploadRemove() {
      this.fileList = [];
      this.sendParams.fileName = "";
    },
    async startSend() {
      if (this.sendParams.fileName == "") {
        this.$notify.error("请先上传您的简历");
        return;
      }
      this.sendParams.jobId = this.nowJob.id;
      try {
        this.sending = true;
        let res = await this.$api.sendMail(this.sendParams);
        this.sending = false;
        if (res.code != 0) {
          this.$notify.error(res.msg);
          return;
        }
        this.$notify.success("投递成功!");
        this.show = false;
        this.fileList = [];
        Object.assign(this.$data.sendParams, this.$options.data().sendParams);
      } catch (error) {
        this.sending = false;
        this.$notify.error(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.pangod-classify {
  font-size: 16px;
  background-color: #eeeef0;

  --frame-width: 1500px;

  &::before {
    content: "";
    display: table;
  }

  .navBar {
    background: #eeeef0;

    .pangod-classify-nav {
      width: var(--frame-width);
      margin: 0 auto;
      background-color: #fff;
      line-height: 60px;

      .pangod-classify-nav-ul {
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: center;

        .pangod-classify-nav-li {
          padding: 0 20px;
          text-align: center;
          cursor: pointer;
        }
      }
      .active {
        color: rgba(2, 69, 140, 0.7);
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 50%;
          height: 3px;
          background: rgba(2, 69, 140, 0.7);
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          z-index: 2;
          opacity: 1;
        }
      }
    }
  }

  .pangod-classify-content {
    width: var(--frame-width);
    margin: 0 auto 20px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #ffffff;

    .van-loading {
      text-align: center;
    }

    .pangod-classify-content-jobitem {
      color: #333;
      padding: 30px 0;
      border-bottom: 1px solid #f1f1f1;

      h3 {
        display: flex;
        align-items: center;

        .val {
          margin-left: 20px;
          font-size: 14px;
          font-weight: 400;
        }
        a {
          color: $theme;
          text-decoration: underline;
        }
        b {
          color: $theme;
        }
      }
      .info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;
      }
      .left {
        width: calc(50% - 32px);
      }
      .right {
        width: calc(50% - 32px);
        padding: 0 32px;
      }
      .left,
      .right {
        .description {
          color: #333;
          line-height: 2;
          font-size: 15px;
          white-space: pre-wrap;
        }
      }
      .email {
        margin-left: 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width: 1600px) {
  .pangod-classify {
    --frame-width: 1200px;
  }
  .pangod-classify .pangod-classify-content {
    padding: pxToRem(10) pxToRem(20);
    box-sizing: border-box;

    .pangod-classify-html {
      width: 100%;

      table {
        max-width: 100% !important;
        height: auto !important;
        margin: 0 auto;

        tr {
          height: auto !important;

          td {
            height: auto !important;
            padding-left: 0 !important;
          }
        }
      }
    }
  }
  .pangod-classify-nav-li {
    font-size: 14px;
  }
}
@media (max-width: 1300px) {
  .pangod-classify {
    --frame-width: 1000px;
  }
}
@media (max-width: 1200px) {
  .pangod-classify {
    --frame-width: 100%;
  }
  .pangod-classify .pangod-classify-content {
    box-sizing: border-box;
    margin-bottom: pxToRem(10);
  }

  .pangod-classify .navBar .pangod-classify-nav {
    line-height: 30px;

    .pangod-classify-nav-ul {
      justify-content: start;
      white-space: nowrap;
      overflow-x: scroll;
      padding: pxToRem(5) pxToRem(10);
    }
  }
}
@media (max-width: 750px) {
  .pangod-classify .navBar .pangod-classify-nav {
    line-height: pxToRem(40);

    .pangod-classify-nav-ul {
      font-size: pxToRem(15);
    }
  }
  .pangod-classify .pangod-classify-content .pangod-classify-content-jobitem {
    font-size: pxToRem(16);

    img {
      width: pxToRem(20);
    }

    h3 {
      display: block;

      .val {
        font-size: pxToRem(13);
        display: block;
        margin-left: 0;
        margin-top: 10px;
      }
      .email {
        margin-left: 0;
        margin-top: 5px;
        display: none;
      }
    }

    .left,
    .right {
      width: 100%;
      float: none;
      padding: 0;
    }
  }
}
</style>

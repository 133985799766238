// 字典有两级 组件
<template>
  <div class="justTwo">
    <div class="top">
      <div class="title">{{ list.name | handleShowName }}</div>
      <div class="ask" @click="toBottom">免费咨询</div>
    </div>
    <div class="center">{{ list.contextStrs }}</div>
    <div class="bottom">
      <ul class="tabs">
        <li
          :class="['tab', active == idx ? 'active' : '']"
          v-for="(item, idx) in list.sysDictionaryNewVos"
          :key="item.id"
          @click="changeItem(item, idx)"
        >
          {{ item.name | handleShowName }}
        </li>
      </ul>
      <div class="content">
        <div class="desc" v-if="!isMobile" v-html="nowItem.contentStr"></div>
        <div
          class="desc"
          v-else
          v-html="
            nowItem.mobileContentStr
              ? nowItem.mobileContentStr
              : nowItem.contentStr
          "
        ></div>
        <!-- <img class="img" :src="URL + nowItem.newsImageUrl" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "../../util/config";
import { Store } from "../../store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      active: 0,
      nowItem: {},
      URL,
    };
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
  },
  props: ["list"],
  watch: {
    list(val) {
      try {
        this.nowItem = val.sysDictionaryNewVos[0].sysNew[0];
      } catch (error) {
        this.nowItem = {};
      }
    },
  },
  created() {
    if (this.list.sysDictionaryNewVos.length > 0) {
      this.nowItem = this.list.sysDictionaryNewVos[0].sysNew[0];
    }
  },
  mounted() {},
  methods: {
    changeItem(item, idx) {
      this.active = idx;
      this.nowItem = item.sysNew[0];
    },
    // 滚动至底部
    toBottom() {
      this.$emit("scrollTo");
    },
  },
};
</script>

<style lang="scss" scoped>
.justTwo {
  width: 1000px;
  margin: 0 auto 70px;
  color: rgb(125, 126, 129);

  .top {
    width: fit-content;
    position: relative;
    .title {
      font-size: 27px;
      font-weight: 400;
      padding: 10px 0;
      position: relative;
      color: #000;

      &::after {
        content: "";
        display: block;
        width: 50%;
        height: 3px;
        background-color: $theme;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
    .ask {
      white-space: nowrap;
      font-size: 18px;
      color: #fff;
      padding: 2px 10px;
      border-radius: 3px;
      background-color: $theme;
      position: absolute;
      left: 120%;
      top: 50%;
      transform: translateY(-50%);
      &:hover {
        cursor: pointer;
      }
    }
  }
  .center {
    margin: 20px 0;
    text-align: center;
  }
  .bottom {
    .tabs {
      display: flex;
      justify-content: space-around;
      font-size: 15px;
      padding: 30px;
      padding-bottom: 0;

      .tab {
        width: 132px;
        border: 2px solid rgba(44, 114, 255, 0.1);
        border-radius: 10px;
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:hover {
          cursor: pointer;
          border-color: $theme;
        }
        &::before,
        &::after {
          content: "";
          border-style: solid;
          position: absolute;
          bottom: -16px;
          left: 50%;
          transform: translateX(-50%);
          display: none;
        }
        &::before {
          border-width: 8px;
          border-color: $theme transparent transparent transparent;
        }
        &::after {
          border-width: 11px;
          border-color: #fff transparent transparent transparent;
        }
      }

      .tab:hover {
        color: $theme;
        cursor: pointer;
      }
      .active {
        color: #444;
        border-color: $theme;
        font-weight: 600;
        &::before,
        &::after {
          display: block;
        }
      }
    }
    .content {
      margin-top: 30px;
      font-size: 20px;
      color: #333;

      .desc {
        width: 100%;
        padding-bottom: 40px;
        border-bottom: 1px dashed #ddd;
        letter-spacing: 0.5px;
        text-align: justify;
      }

      .img {
        width: 100%;
      }
    }
  }
}
@media (max-width: 1300px) {
  .justTwo {
    width: 100%;
  }
}
@media (max-width: 750px) {
  .justTwo {
    margin-bottom: 20px;

    .top {
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .ask {
        font-size: 16px;
      }
    }
    .bottom {
      .desc {
        padding-top: 20px;
      }
    }
  }
}
</style>

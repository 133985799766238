<template>
  <div class="pangod-classify">
    <!-- 顶部 -->
    <BreadCrumb :routeArr="routeArr" v-show="!isMobile"></BreadCrumb>

    <!-- 二级导航栏 -->
    <TypeNavs
      :activeCode="activeCode"
      :subActiveCode="subActiveCode"
      :tabNavList="tabNavList"
      :thirdNavlist="thirdNavlist"
      @changeNav="changeNav"
      @changechildNav="changechildNav"
    />
    <!-- 内容 -->
    <!-- 除招贤纳士和联系我们的其他一级导航栏 -->
    <div class="pangod-classify-content">
      <CustomLoading v-show="vanLoading" />
      <!-- 业务介绍 box -->
      <div class="business-box" v-if="busLen">
        <div class="logo">
          <img :src="URL + businessList.Logo" alt />
        </div>

        <!-- 业务介绍 component -->
        <component
          :is="dealShowList(item)"
          v-for="(item, key) in businessList"
          :key="key"
          :list="item"
          @scrollTo="scrollTo"
        ></component>
        <component
          :is="busLen && which_show"
          :parentCode="params.newsCode"
        ></component>
      </div>
    </div>

    <Tabbar v-if="isMobile"></Tabbar>
  </div>
</template>
<script>
import Tabbar from "@/components/tabbar.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import JustOne from "@/components/business/justOne.vue";
import JustTwo from "@/components/business/justTwo.vue";
import ThreeMore from "@/components/business/threeMore.vue";
import Contact from "@/components/business/contact.vue";
import CustomLoading from "@/components/loading.vue";
import TypeNavs from "../components/typeNavs.vue";

import { URL } from "@/util/config.js";
import { Store } from "../store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      URL: URL,
      tabNavList: [],
      thirdNavlist: [],
      activeCode: "", //当前菜单code
      subActiveCode: "", //当前子菜单code
      forthNavList: [],
      htmlContent: "", //直接访问时富文本内容
      mobileHtml: "", // 移动端 富文本内容
      formList: [],
      hisformList: [], //过往合作资质
      detail: "",
      params: {
        pageIndex: 1,
        pageSize: 12,
        newsCode: undefined,
        typeCode: undefined,
      },
      totalData: 0,
      scroll: 0, //判断页面是向上还是向下滚动
      which_show: "contact", // 默认展示组件
      showList: [
        { id: "0", component: "justOne" },
        { id: "1", component: "justTwo" },
        { id: "2", component: "threeMore" },
        { id: "lxwm", component: "contact" },
      ],
      businessList: {},
      routeArr: [],
      titleClass: "active-cell",
      scrollTop: 0,
      vanLoading: false,
    };
  },
  components: {
    BreadCrumb,
    Tabbar,
    JustOne,
    JustTwo,
    ThreeMore,
    Contact,
    CustomLoading,
    TypeNavs,
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
    dealShowList() {
      return (item) => {
        let str = "";
        if (item) {
          this.showList.forEach((ele) => {
            if (ele.id == item.powerLevel) {
              str = ele.component;
            }
          });
        }

        return str;
      };
    },
    busLen() {
      let bus = JSON.stringify(this.businessList);
      return bus.length !== 2;
    },
  },
  created() {},
  mounted() {
    this.setPageConfig();
  },
  methods: {
    // 构建页面参数
    setPageConfig() {
      const query = this.$route.params;
      // 父子id
      const { id, childId } = query;
      out: for (const i in this.navList) {
        const ele = this.navList[i];
        if (ele.id == id) {
          // 获取二级分类
          this.tabNavList = ele.children;
          if (childId) {
            // 两个参数
            for (const j in ele.children) {
              const child = ele.children[j];
              // 获取激活字典
              if (child.id == childId) {
                this.activeCode = child.code;
                break out;
              }
            }
          } else {
            // 一级字典
            this.activeCode = ele.code;
            break;
          }
        }
      }
      this.getChildNavList();
      this.routeArr = [{ name: "首页", path: "/" }, "业务介绍"];
    },
    // 获取二级导航栏的子集导航栏（三级导航栏）api
    async getChildNavList() {
      let res = await this.$api.searchDictionary({
        parentCode: this.activeCode,
        flag: 1,
      });

      if (res.code === 0) {
        let third = this.$route.query.sub; // 指定第三级激活字典
        this.thirdNavlist = res.data;
        // 若从首页直接查看三级字典则直接赋值，否则默认激活第一个
        if (third) {
          this.subActiveCode = third;
        } else {
          this.subActiveCode =
            this.thirdNavlist[0] && this.thirdNavlist[0].code;
        }

        // 业务字典
        this.changechildNav(this.subActiveCode);
      }
    },
    /**
     *  切换菜单
     */
    changeNav(val) {
      this.$router.push(
        val.templateName + "_" + this.$route.params.id + "/" + val.id
      );
    },
    // 三级字典切换
    async changechildNav(code) {
      this.subActiveCode = code;
      this.vanLoading = true;

      let res = await this.$api.getBusinessByCode({ parentCode: code });
      if (res.code == 0) {
        this.businessList = res.data;
      }
      this.vanLoading = false;
    },
    scrollTo() {
      const top = document.body.clientHeight - 1300;
      window.scroll({ top: top, left: 0, behavior: "smooth" });
    },
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.pangod-classify {
  font-size: 16px;
  background-color: #eeeef0;

  --frame-width: 1500px;

  &::before {
    content: "";
    display: table;
  }

  .navBar {
    background: #eeeef0;
  }

  .pangod-classify-content {
    width: var(--frame-width);
    margin: 0 auto 20px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #ffffff;

    .van-loading {
      text-align: center;
    }
  }
  .logo {
    width: 1000px;
    height: 50px;
    text-align: left;
    margin: 0 auto 50px;
    margin-left: 200px;

    img {
      height: 100%;
      margin-right: 20px;
    }
  }
}
@media (max-width: 1600px) {
  .pangod-classify {
    --frame-width: 1200px;
  }
  .pangod-classify .pangod-classify-content {
    padding: pxToRem(10) pxToRem(20);
    box-sizing: border-box;
  }
  .pangod-classify .logo {
    margin-left: 50px;
  }
}
@media (max-width: 1300px) {
  .pangod-classify {
    --frame-width: 1000px;
  }
}
@media (max-width: 1200px) {
  .pangod-classify {
    --frame-width: 100%;

    .logo {
      width: auto;
      margin-left: -6px;
    }
    .pangod-classify-content {
      margin-bottom: pxToRem(10);
    }
  }
}
@media (max-width: 750px) {
  .pangod-classify .logo {
    width: auto;
    height: 40px;
    margin: 0 auto 20px;
  }
}
</style>

<template>
  <div class="pangod-classify">
    <!-- 顶部 -->
    <BreadCrumb :routeArr="routeArr" v-show="!isMobile"></BreadCrumb>

    <!-- 二级导航栏 -->
    <div class="navBar">
      <!-- 二级为分类导航时 -->
      <div class="pangod-classify-choosetype" v-if="!isMobile">
        <div class="ctype" v-for="item in typeList" :key="item.code">
          <div class="title">{{ item.name }}</div>
          <ul style="flex-shrink: 0">
            <li
              :class="{ active: item.activeCode === '' }"
              @click="changeNav({ name: '全部', code: '' }, item)"
            >
              全部
            </li>
          </ul>
          <ul>
            <li
              :class="{ active: item.activeCode === child.code }"
              v-for="child in pinyinSort(item.children)"
              :key="child.code"
              @click="changeNav(child, item)"
            >
              {{ child.name }}
            </li>
          </ul>
        </div>
      </div>

      <!-- 案例展示 导航栏 -->
      <van-dropdown-menu active-color="#3c79f3" v-else>
        <van-dropdown-item
          :ref="'item' + idx"
          v-for="(item, idx) in typeList"
          :key="item.code"
          :title="item.name"
        >
          <template #default>
            <van-cell
              :title-class="item.activeCode == '' ? titleClass : ''"
              center
              title="全部"
              @click="changeNav({ name: '全部', code: '' }, item, idx)"
            />
            <van-cell
              :title-class="item.activeCode == val.code ? titleClass : ''"
              center
              :title="val.name"
              v-for="val in item.children"
              :key="val.code"
              @click="changeNav(val, item, idx)"
            />
          </template>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <!-- 内容 -->
    <!-- 除招贤纳士和联系我们的其他一级导航栏 -->
    <div class="pangod-classify-content">
      <CustomLoading v-show="vanLoading" />
      <!-- 列表展示模式 -->
      <div class="example-box">
        <div
          class="pangod-classify-listitem"
          :style="{ animationDelay: idx * 0.1 + 's' }"
          v-for="(item, idx) in formList"
          :key="item.id"
          @click="getInfo(item)"
        >
          <div class="left">
            <img :src="URL + item.newsImageUrl" />
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="type" v-for="type in item.codeData" :key="type.id">
              <p>{{ type.value }}</p>
              <p>{{ type.name }}</p>
            </div>
          </div>
        </div>

        <div class="example-empty" v-show="!vanLoading && formList.length == 0">
          <el-empty
            :image="require('@/assets/empty.png')"
            description="暂无相关案例"
            :image-size="200"
          ></el-empty>
        </div>
      </div>
      <el-pagination
        class="pagination"
        :hide-on-single-page="formList.length <= 12"
        @current-change="handleCurrentChange"
        :current-page="params.pageIndex"
        :page-size="params.pageSize"
        layout="total, prev, pager, next"
        :total="totalData"
        :small="isMobile"
      ></el-pagination>
    </div>

    <Tabbar v-if="isMobile"></Tabbar>
  </div>
</template>
<script>
import Tabbar from "@/components/tabbar.vue";
import BreadCrumb from "@/components/breadCrumb.vue";
import CustomLoading from "@/components/loading.vue";

import { URL } from "@/util/config.js";
import { Store } from "../store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      URL: URL,
      tabNavList: [],
      formList: [],
      totalData: 0,
      tabName: "", //菜单名称
      params: {
        pageIndex: 1,
        pageSize: 12,
        newsCode: "",
        typeCode: ",,",
      },
      typeList: [], //类型数据
      routeArr: [],
      titleClass: "active-cell",
      vanLoading: false,
      nowDic: {},
    };
  },
  components: {
    BreadCrumb,
    Tabbar,
    CustomLoading,
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
    pinyinSort() {
      return (children) => {
        let child = children ? [...children] : [];
        return child.sort((a, b) => {
          if (a.name !== "其他" && b.name !== "其他") {
            return a.name.localeCompare(b.name, "zh-Hans-CN", {
              sensitivity: "accent",
            });
          } else if (a.name == "其他") {
            return 1;
          } else {
            return -1;
          }
        });
      };
    },
  },
  mounted() {
    this.setPageConfig();
  },
  methods: {
    // 构建页面参数
    setPageConfig() {
      // 父子id
      const { id, childId } = this.$route.params;
      out: for (const i in this.navList) {
        const ele = this.navList[i];
        if (ele.id == id) {
          // 获取二级分类
          this.tabNavList = ele.children;
          if (childId) {
            // 两个参数
            for (const j in ele.children) {
              const child = ele.children[j];
              // 获取激活字典
              if (child.id == childId) {
                this.nowDic = child;
                this.getList();
                break out;
              }
            }
          } else {
            // 一级字典
            this.nowDic = ele;
            this.getList();
            break;
          }
        }
      }

      this.getTypeDic();
      this.routeArr = ["首页", this.nowDic.name];
    },
    async getTypeDic() {
      //获取营销案例的筛选数据
      let res = await this.$api.typeDic({ type: 1 });
      if (res.code === 0) {
        this.typeList = res.data.map((ele) => {
          ele.activeCode = "";
          return ele;
        });
      }
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getList();
    },
    getList() {
      this.vanLoading = true;

      // 获取列表
      this.$api
        .sysNewsList(this.params)
        .then((res) => {
          if (res.code === 0) {
            this.formList = res.data.list;
            this.totalData = res.data.total;
          }
        })
        .finally(() => {
          this.vanLoading = false;
        });
    },
    /**
     *  切换菜单
     *  choseType 根据nav code获取所有新闻列表
     *  idx 有值则为移动端营销案例类型nav点击事件
     */
    changeNav(val, choseType, idx) {
      this.params.pageIndex = 1;
      // 激活赋值
      choseType.activeCode = val.code;
      if (idx !== undefined) {
        this.$refs["item" + idx][0].toggle(false);
      }

      // 营销案例时，typeCode 由多个筛选code组成
      const activeArr = this.typeList.map((ele) => {
        return ele.activeCode;
      });
      this.params.typeCode = activeArr.join(",");
      this.getList();
    },
    getInfo(item) {
      const id = this.$route.params.id;
      this.$router.push(`/exampleDetail_${id}/${item.id}`);
    },
  },
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = to.path.indexOf("exampleDetail") != -1;
    next();
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.pangod-classify {
  font-size: 16px;
  background-color: #eeeef0;

  &::before {
    content: "";
    display: table;
  }

  .van-dropdown-item__content {
    max-height: 60%;

    .van-cell__title {
      font-size: pxToRem(14);
      line-height: normal;
    }
  }
  .van-dropdown-menu__bar {
    height: pxToRem(30);
  }
  .van-dropdown-menu__title {
    font-size: pxToRem(15);
    line-height: normal;

    &::after {
      border-width: pxToRem(5);
      right: pxToRem(-10);
    }
  }

  .navBar {
    background: #eeeef0;
    .pangod-classify-choosetype {
      width: 1500px;
      margin: 0 auto 10px;
      box-sizing: border-box;
      background-color: #fff;

      .ctype {
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        padding: 15px 40px;
        border-top: 1px solid #eee;

        .title {
          width: 80px;
          font-weight: bold;
          margin-right: 10px;
          font-size: 16px;
          color: #91c81f;
          flex-shrink: 0;
        }
      }
      div {
        &:last-child {
          padding-bottom: 20px;
        }
        ul {
          min-height: 20px;
          line-height: 1.5;
          display: flex;
          flex-wrap: wrap;

          li {
            margin-right: 10px;
            padding: 2px 8px;
            border-radius: 20px;
            cursor: pointer;

            &:hover {
              color: #02458c;
            }
          }
          .active {
            color: #fff !important;
            background-color: #02458c;
          }
        }
      }
    }
  }

  .pangod-classify-content {
    width: 1440px;
    margin: 0 auto 20px;
    padding: 30px;
    background-color: #ffffff;

    .van-loading {
      text-align: center;
    }

    .example-empty {
      text-align: center;
      margin: 0 auto;
    }

    .example-box {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .pangod-classify-listitem {
    width: calc(25% - 22px);
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #eee;
    margin: 0 10px 20px;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-8px);

      img {
        transform: scale(1.05);
      }
    }

    .left {
      width: 100%;
      height: 200px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        transition: all 0.3s;
      }
    }
    .right {
      width: 100%;
      text-align: center;
      padding-top: 10px;

      &::after {
        content: "";
        display: block;
        clear: both;
      }
      .type {
        width: 50%;
        float: left;
        text-align: center;

        p {
          font-size: 13px;
        }

        p:first-child {
          font-weight: bold;
          font-size: 14px;
          color: #02458c;
        }
      }
      .title {
        width: 100%;
        color: grey;
        font-size: 15px;
        font-weight: bold;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .create {
        width: 100%;
        font-size: 16px;
        display: inline-block;
        color: #919797;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .description {
        width: 100%;
        font-size: 14px;
        display: inline-block;
        color: #5f6464;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .example-empty {
      text-align: center;
    }
  }

  .active-cell {
    color: $theme;
  }
}
@media (max-width: 1600px) {
  .pangod-classify .navBar .pangod-classify-choosetype {
    width: 1200px;
  }
  .pangod-classify .pangod-classify-content {
    padding: pxToRem(10);
    width: 1200px;
    box-sizing: border-box;

    .backto {
      left: 0;
      top: 0;
    }
  }
  .pangod-classify {
    .pangod-classify-listitem .left {
      height: 144px;
    }
  }
}
@media (max-width: 1300px) {
  .pangod-classify {
    .navBar {
      .pangod-classify-choosetype {
        width: 1000px;
      }
      .pangod-classify-choosetype div ul li {
        margin-right: 15px;
      }
    }

    .pangod-classify-content {
      width: 1000px;

      .pangod-classify-listitem {
        width: calc(33% - 20px);

        .left {
          height: 165px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .pangod-classify .navBar .pangod-classify-choosetype {
    width: 100%;
  }
  .pangod-classify .pangod-classify-content {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: pxToRem(10);
  }
}

@media (max-width: 750px) {
  .pangod-classify .pangod-classify-content .pangod-classify-listitem {
    width: 49%;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    overflow: hidden;

    &:nth-child(2n) {
      margin-left: 2%;
    }

    .left {
      height: pxToRem(100);
    }
    .right {
      padding: 5px 5px 0;
      box-sizing: border-box;
    }
  }
}
</style>

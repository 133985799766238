<template>
  <div class="index">
    <!-- banner模块 -->
    <div class="index-banner">
      <video
        v-if="!isMobile"
        class="video"
        muted
        autoplay
        loop
        preload="auto"
        webkit-playsinline
        playsinline
        x5-video-player-type="h5"
        x5-video-orientation="portraint"
        :src="
          URL + '%E5%8A%9E%E5%85%AC%E5%AE%A415%E7%A7%92%E7%8E%AF%E5%A2%83.mp4'
        "
      ></video>
      <div id="swiper_banner" v-else>
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img class="banner" :src="URL + 'banner1.JPG'" />
          </div>
          <div class="swiper-slide">
            <img class="banner" :src="URL + 'banner2.jpg'" />
          </div>
          <div class="swiper-slide">
            <img class="banner" :src="URL + 'banner4.jpg'" />
          </div>
          <div class="swiper-slide">
            <img class="banner" :src="URL + 'banner5.jpg'" />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <!-- pc端 -->
    <div class="index-body" v-if="!isMobile" style="background-color: #fff">
      <!-- 全品类 -->
      <div class="index-product">
        <div
          class="
            index-product-title
            title-all title-center
            wow
            animate__fadeInUp
          "
          data-wow-delay="0.5s"
        >
          全品类
          <div class="subtitle-all">智能营销</div>
        </div>
        <div class="index-product-content">
          <div class="wrap">
            <ul class="content">
              <li
                class="list wow animate__fadeInUp"
                :data-wow-delay="0.5 + idx * 0.1 + 's'"
                v-for="(item, idx) in bussinessList"
                :key="item.code"
              >
                <div class="title">{{ item.name }}</div>
                <div class="desc" v-html="Deldesc(item.contextStrs)"></div>
                <div class="part">
                  <div
                    :class="{ item: true, active: child.active }"
                    v-for="(child, idx) in item.children"
                    :key="child.code"
                    @mouseenter="onSelect(item, child)"
                    @click="getBusinessInfo(child, item, idx)"
                  >
                    <div class="item-more item-box" v-if="idx == 3">····</div>
                    <div class="item-box" v-else>
                      <img
                        class="icon"
                        :src="URL + child.imgUrl"
                        v-if="child.imgUrl"
                        alt=""
                      />
                      {{ child.name }}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <img
            class="prev"
            src="../assets/index/home_pro_prev.png"
            alt="向左箭头"
            @click="typeMove('prev')"
          />
          <img
            class="next"
            src="../assets/index/home_pro_next.png"
            alt="向右箭头"
            @click="typeMove('next')"
          />
        </div>
        <div
          class="product-more"
          @click="getMore('/business_29/' + bussinessList[0].id)"
        >
          查看详情
        </div>
      </div>
      <!-- 全领域 -->
      <div class="index-domain">
        <div
          class="title-center title-all wow animate__fadeInUp"
          data-wow-delay="0.5s"
        >
          全领域
          <div class="subtitle-all">多渠道优势聚合</div>
        </div>
        <img
          class="wow animate__fadeInUp"
          src="http://hr.pangodso.com/static/homepagebg2.png"
          data-wow-delay="1s"
        />
      </div>
      <!-- 服务保障体系 -->
      <div class="index-system">
        <div
          class="title-center title-all wow animate__fadeInUp"
          data-wow-delay="0.5s"
        >
          服务保障体系
          <div class="subtitle-all">
            “1+2+6”服务及保障体系致力于为广大客户朋友提供专业的互联网广告营销服务
          </div>
        </div>
        <img
          class="wow animate__fadeInUp"
          data-wow-delay="0.5s"
          src="http://hr.pangodso.com/static/homepagebg3_3.png"
          alt=""
        />
        <div class="system-more" @click="getMore('/content_17/26')">
          查看详情
        </div>
      </div>

      <!-- 业务介绍 -->
      <div class="index-descript">
        <div
          class="title-center title-all wow animate__fadeInUp"
          data-wow-delay="0.5s"
          style="padding-bottom: 4px"
        >
          公司介绍
        </div>
        <div class="index-descript-title">
          {{ globalConfig.companyIntroduct }}
        </div>
        <div
          class="index-descript-content"
          @mouseenter="removeInterval"
          @mouseleave="setInterval"
        >
          <div :class="{ 'descript-wrap': true, opacity: moduleOpc }">
            <div
              class="index-descript-content-item"
              v-for="item in nowIntroList"
              :key="item"
            >
              <div class="post">
                <img :src="URL + item" class="show" />
              </div>
            </div>
          </div>
          <img
            v-if="introList.length > 3"
            class="prev"
            src="../assets/index/home_pro_prev.png"
            alt="向左箭头"
            @click="moduleMove('prev')"
          />
          <img
            v-if="introList.length > 3"
            class="next"
            src="../assets/index/home_pro_next.png"
            alt="向右箭头"
            @click="moduleMove('next')"
          />
        </div>
      </div>

      <!-- 企业规模+服务范围 -->
      <div class="index-scale">
        <div class="scale wow animate__fadeInLeft" data-wow-delay="0.5s">
          <p>企业规模</p>
          <div class="box">
            <img src="@/assets/index/bg.png" alt="" />
            <div class="left-top">
              <div class="number">
                <span class="num">
                  <countTo
                    ref="count1"
                    :startVal="0"
                    :endVal="teamNum"
                    :duration="3000"
                    :autoplay="false"
                    separator=""
                  ></countTo>
                </span>
                <span class="unit topUnit">+</span>
              </div>
              合作客户
            </div>
            <div class="right-top">
              <div class="number">
                <span class="num">
                  <countTo
                    ref="count2"
                    :startVal="0"
                    :endVal="activeNum"
                    :duration="3000"
                    :autoplay="false"
                    separator=""
                  ></countTo>
                </span>
                <span class="unit topUnit">+</span>
              </div>
              活跃客户
            </div>
            <div class="center">
              <div class="number">
                <span class="num">
                  <countTo
                    ref="count3"
                    :startVal="0"
                    :endVal="yearNum"
                    :duration="3000"
                    :autoplay="false"
                    separator=""
                  ></countTo>
                </span>
                <span class="unit">年</span>
              </div>
              从业经验
            </div>
            <div class="left-bot">
              <div class="number">
                <span class="num">
                  <countTo
                    ref="count4"
                    :startVal="0"
                    :endVal="areaNum"
                    :duration="3000"
                    :autoplay="false"
                    separator=""
                  ></countTo>
                </span>
                <span class="unit">㎡</span>
              </div>
              甲级写字楼
            </div>
            <div class="right-bot">
              <div class="number">
                <span class="num">
                  <countTo
                    ref="count5"
                    :startVal="0"
                    :endVal="memberNum"
                    :duration="3000"
                    :autoplay="false"
                    separator=""
                  ></countTo>
                </span>
                <span class="unit topUnit">+</span>
                <span class="unit">人</span>
              </div>
              服务人员
            </div>
          </div>
        </div>
        <div class="range wow animate__fadeInRight" data-wow-delay="1s">
          <p>服务范围</p>
          <div class="box">
            <img src="http://hr.pangodso.com/static/homepagebg5_2.jpg" alt="" />
          </div>
        </div>
      </div>
      <!-- 案例展示 -->
      <div class="index-example">
        <div class="index-example-title title-all title-left">
          案例展示
          <div class="more" @click="getMore('/examplelist_36')">查看更多>></div>
        </div>
        <div class="index-example-content">
          <div id="certify">
            <div class="swiper-wrapper" v-if="exampleList.length !== 0">
              <div
                class="swiper-slide"
                v-for="item in exampleList"
                :key="item.id"
              >
                <div
                  class="index-example-content-item"
                  @click="toExampleDetail(item)"
                >
                  <img :src="URL + item.newsImageUrl" alt="" />
                  <div class="index-example-content-item-title">
                    <h4>{{ item.title }}</h4>
                    <div class="index-example-txt">
                      {{ item.description }}
                    </div>
                  </div>
                  <div class="index-example-codeData">
                    <div
                      class="val"
                      v-for="val in item.codeData"
                      :key="val.code"
                    >
                      <div class="value">{{ val.value }}</div>
                      <div class="title">{{ val.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>

            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>

      <!-- 新闻资讯 -->
      <div class="index-new">
        <div class="index-new-title title-all title-left">
          新闻资讯
          <div class="more" @click="getMore('/newList_37/' + newTypes[0].id)">
            查看更多>>
          </div>
        </div>
        <div class="index-new-content">
          <ul class="type-box">
            <li
              :class="['li', nowNewType.code == item.code ? 'active' : '']"
              v-for="item in newTypes"
              :key="item.id"
              @click="changeNewType(item.code)"
            >
              {{ item.name }}
            </li>
          </ul>
          <div class="content-box" v-if="newsList.length != 0">
            <div
              class="new"
              v-for="item in newsList"
              :key="item.id"
              @click="toNewDetail(item)"
            >
              <div class="left">
                <img :src="URL + item.newsImageUrl" alt="" />
              </div>
              <div class="right">
                <div class="title">{{ item.title }}</div>
                <div class="time">
                  {{ (item.publishTime || item.createTime) | handleTime }}
                </div>
                <div class="desc">{{ item.description }}</div>
              </div>
            </div>
          </div>
          <div class="message" v-else>目前并无相应资讯...</div>
        </div>
      </div>

      <!-- 旗下公司的友情链接 -->
      <div class="index-link">
        <div class="div3_title">
          <div class="div3_title1">集团及旗下公司</div>
          <div class="themeLine"></div>
          <div class="line"></div>
        </div>
        <ul>
          <li class="div3_li" v-for="item in friendLink" :key="item.id">
            <a :href="item.hrefUrl" target="blank">{{ item.name }}</a>
          </li>
        </ul>
      </div>

      <!-- 底部留言 -->
      <div class="index-message">
        <div class="content">
          <p>留言咨询</p>
          <div class="ipt-frame">
            <input
              type="text"
              placeholder="姓名"
              class="ipt"
              v-model="message.userName"
            />
            <input
              type="number"
              placeholder="手机"
              class="ipt"
              v-model="message.phone"
            />
            <input
              type="text"
              placeholder="公司名称"
              class="ipt"
              v-model="message.title"
            />
            <input
              type="text"
              placeholder="所属区域"
              class="ipt"
              v-model="message.address"
            />
            <textarea
              placeholder="留言内容"
              class="textarea"
              v-model="message.content"
            ></textarea>
          </div>
          <button class="submit" @click="submit">提 交</button>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div class="mobile-index" v-else>
      <!-- 我们的服务 -->
      <div class="mobile-service">
        <div class="service-title mobile-title">
          <h2>我们的服务</h2>
        </div>
        <div class="service-body">
          <div class="wrap">
            <ul class="busList">
              <li
                class="item"
                v-for="item in bussinessList"
                :key="item.id"
                @click="getMore('/business_29/' + bussinessList[0].id)"
              >
                <div><img class="icon" :src="URL + item.imgUrl" alt="" /></div>

                <div class="text" v-html="DealBusName(item.name)"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 关于我们 -->
      <div class="mobile-about">
        <div class="mobile-title">
          <h2>关于我们</h2>
        </div>
        <div class="about-body">
          <div class="desc">{{ globalConfig.companyIntroduct }}</div>
          <div class="about-swipe">
            <van-swipe :autoplay="5000" @change="swipeChange">
              <van-swipe-item v-for="item in introList" :key="item">
                <el-image :src="URL + item" fit="cover"></el-image>
              </van-swipe-item>
              <template #indicator>
                <div class="custom-indicator">
                  {{ currentSwipe + 1 }}/{{ introList.length }}
                </div>
              </template>
            </van-swipe>
          </div>

          <div class="about-num">
            <div class="num-box">
              <div class="text">广告经验</div>
              <div class="number">
                <countTo
                  ref="count1"
                  :startVal="0"
                  :endVal="yearNum"
                  :duration="3000"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="unit">年</span>
              </div>
            </div>
            <div class="num-box">
              <div class="text">服务人员</div>
              <div class="number">
                <countTo
                  ref="count2"
                  :startVal="0"
                  :endVal="memberNum"
                  :duration="3000"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="unit topUnit">+</span>
                <span class="unit">人</span>
              </div>
            </div>
            <div class="num-box">
              <div class="text">合作客户</div>
              <div class="number">
                <countTo
                  ref="count3"
                  :startVal="0"
                  :endVal="teamNum"
                  :duration="3000"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="unit topUnit">+</span>
              </div>
            </div>
            <div class="num-box">
              <div class="text">甲级写字楼</div>
              <div class="number">
                <countTo
                  ref="count4"
                  :startVal="0"
                  :endVal="areaNum"
                  :duration="3000"
                  :autoplay="false"
                  separator=""
                ></countTo>
                <span class="unit">m</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 案例展示 -->
      <div class="mobile-example">
        <div class="mobile-title">
          <h2>案例展示</h2>
        </div>
        <div class="example-box">
          <div
            class="example-item"
            v-for="item in exampleList"
            :key="item.id"
            @click="toExampleDetail(item)"
          >
            <img style="display: block" :src="URL + item.newsImageUrl" alt="" />
            <div class="item-title">{{ item.title }}</div>
            <div class="codeData">
              <div class="val" v-for="val in item.codeData" :key="val.code">
                <div class="value">{{ val.value }}</div>
                <div class="title">{{ val.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile-more" @click="getMore('/examplelist_36')">
          查看更多
        </div>
      </div>

      <!-- 新闻资讯 -->
      <div class="mobile-news">
        <div class="mobile-title">
          <h2>新闻资讯</h2>
        </div>
        <div class="news-body">
          <van-tabs animated color="#409EFF" @change="changeNewType">
            <van-tab
              :title="item.name"
              :name="item.code"
              v-for="item in newTypes"
              :key="item.id"
            >
              <div class="news">
                <div
                  class="new"
                  v-for="item in newsList"
                  :key="item.id"
                  @click="toNewDetail(item)"
                >
                  <div class="left">
                    <img :src="URL + item.newsImageUrl" alt="" />
                  </div>
                  <div class="right">
                    <div class="box">
                      <div class="title">{{ item.title }}</div>
                      <div class="time">
                        {{ item.createTime | handleTime }}
                      </div>
                      <div class="desc">{{ item.description }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <div
          class="mobile-more"
          @click="getMore('/newList_37/' + newTypes[0].id)"
        >
          查看更多
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { URL } from "../util/config.js";
import { Store } from "../store";
import { mapState } from "pinia";
import countTo from "vue-count-to";

export default {
  data() {
    return {
      URL: URL,
      moduleOpc: false,
      exampleList: [], //案例展示
      ywCode: "d12644352e8249578dc4b778a128b430", // 业务介绍code
      xwCode: "91bf585dfe9b4a548a9207294ee02208", // 新闻code
      teamNum: 50000,
      activeNum: 16000,
      yearNum: 21,
      areaNum: 8400,
      memberNum: 440,
      moveIndex: 0, // 全品类循环展示下标
      bussinessList: [], // 全品类内容列表
      newTypes: [], //新闻资讯主分类
      nowNewType: {},
      newsList: [], // 新闻资讯内容
      timer: null,
      message: {
        phone: "",
        userName: "",
        address: "",
        content: "",
        title: "",
        dictionaryCode: "",
      }, // 留言参数
      currentSwipe: 0,
      ob: null,
      introList: [], // 公司环境图
      nowIntroIdx: 0, // 展示中的公司环境图下标
      nowIntroList: [], // 展示中的公司环境图
    };
  },
  components: {
    countTo,
  },
  computed: {
    ...mapState(Store, ["friendLink", "isMobile", "navList", "globalConfig"]),
    Deldesc() {
      return (desc) => {
        // 判断是 中文or英文 逗号
        let symbol = desc.indexOf(",") == -1 ? "，" : ",";
        return desc.split(symbol).join("<br />");
      };
    },
    DealBusName() {
      return (name) => {
        let str = "";
        if (name.indexOf("-") != -1) {
          let arr = name.split("-");
          arr[1] = "<span style='color:#1989fa'>" + arr[1] + "</span>";
          str =
            arr[0] +
            "<div style='width:8px;height:1px;background:#333;margin:6px auto'></div>" +
            arr[1];
        } else {
          str = name;
        }
        return str;
      };
    },
  },
  created() {},
  mounted() {
    this.initBanner();
    this.getIndexList();
    this.getAllBusiness();
    this.getNews();
    this.dealIntroList();

    this.observerDom();
  },
  methods: {
    // 环境图处理
    dealIntroList() {
      let { companyIntroductUrl } = this.globalConfig;
      this.introList = companyIntroductUrl.split(",");
      // 第一次获取值后开始轮询
      this.timer = setInterval(() => {
        this.moduleMove("next");
      }, 4000);
    },
    initWow() {
      this.$nextTick(() => {
        new this.$wow.WOW().init();
      });
    },
    initBanner() {
      if (this.isMobile) {
        new window.Swiper("#swiper_banner", {
          loop: true,
          autoplay: true,
          pagination: {
            el: ".swiper-pagination",
            bulletClass: "banner-bullet",
            bulletActiveClass: "banner-bullet-active",
          },
        });
      }
    },
    submit() {
      let reg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (!reg.test(this.message.phone)) {
        this.$notify.error({
          title: "提示",
          message: "请填入正确的手机号码！",
        });
        return;
      }
      this.$api.addFeed(this.message).then((res) => {
        if (res.code == 0) {
          this.$notify.success({
            title: "提示",
            message: "留言提交成功！我们将在收到消息后会尽快与您联系！",
          });

          Object.assign(this.$data.message, this.$options.data().message);
        }
      });
    },
    // 企业介绍轮播定时器
    setInterval() {
      let vm = this;
      this.timer = setInterval(() => {
        vm.moduleMove("next");
      }, 4000);
    },
    removeInterval() {
      clearInterval(this.timer);
    },
    // 首页获取新闻资讯
    getNews() {
      this.newTypes = this.navList.find(
        (ele) => ele.code == this.xwCode
      ).children;
      let childCode = this.newTypes[0].code;
      let params = {
        pageIndex: 1,
        pageSize: 4,
        newsCode: childCode,
      };
      this.$api.sysNewsList(params).then((res) => {
        this.newsList = res.data.list;
        this.nowNewType = this.newTypes[0];
      });
    },
    changeNewType(code) {
      let params = {
        pageIndex: 1,
        pageSize: 4,
        newsCode: code,
      };
      this.nowNewType = this.newTypes.find((ele) => ele.code == code);
      this.$api.sysNewsList(params).then((res) => {
        this.newsList = res.data.list;
      });
    },
    // 点击 首页 新闻资讯 新闻 跳转新闻详情
    toNewDetail(item) {
      const parentNav = this.navList.find((ele) => ele.code == this.xwCode);
      this.$router.push(
        `/newDetail_${parentNav.id}/${this.nowNewType.id}/${item.id}`
      );
    },
    // 首页获取案例展示
    getAllBusiness() {
      this.$api
        .searchByParentCode({ code: this.ywCode, flag: 1 })
        .then((res) => {
          if (res.code == 0) {
            this.bussinessList = res.data.map((ele) => {
              let children = ele.children;
              // 只要前四
              ele.children =
                children.length > 4 ? children.slice(0, 4) : children;
              ele.children.forEach((child) => {
                // 默认未选中
                child.active = false;
              });
              return ele;
            });
          }
        })
        .finally(() => {
          this.initWow();
        });
    },
    // swiper 插件配置函数
    setSwiper() {
      let vm = this;
      // swiper配置参数
      new window.Swiper("#certify", {
        watchSlidesProgress: true, // 监听progress变化
        slidesPerView: "auto", // 一页显示数量
        centeredSlides: true, // 当前页的展示布局
        loop: true, // 反复
        autoplay: {
          disableOnInteraction: false,
          delay: 30000,
        },
        preventClicksPropagation: true,
        followFinger: false, // 松开鼠标后才开始动画
        touchRatio: 0.5, // 两倍移距一倍页数
        observer: true, // 子元素改变时触发swiper更新
        // If we need pagination
        pagination: {
          el: ".swiper-pagination",
        },
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          // 制定轮播策略
          progress: function () {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              var modify = 1;
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.5 + 1;
              }
              var translate = slideProgress * modify * 90 + "px";
              var scale = 1 - Math.abs(slideProgress) / vm.exampleList.length;
              var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.transform(
                "translateX(" + translate + ") scale(" + scale + ")"
              );
              slide.css("zIndex", zIndex);
              slide.css("opacity", 1);
              if (Math.abs(slideProgress) > 3) {
                slide.css("opacity", 0);
              }
            }
          },
          // 制定过度动画
          setTransition: function (transition) {
            for (let i = 0; i < this.slides.length; i++) {
              let slide = this.slides.eq(i);
              slide.transition(transition);
            }
          },
        },
      });
    },
    // 监听页面滚动事件
    observerDom() {
      // 双端处理
      const obClass = this.isMobile ? "about-num" : "index-scale";
      let box = document.getElementsByClassName(obClass)[0];
      this.ob = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setTimeout(() => {
            this.numIncrease();
          }, 600);
          this.ob.unobserve(box);
        }
      });
      this.ob.observe(box);
    },
    // 企业规模数字增长函数
    numIncrease() {
      this.$refs.count1.start();
      this.$refs.count2.start();
      this.$refs.count3.start();
      this.$refs.count4.start();
      this.$refs.count5.start();
    },
    getIndexList() {
      var that = this;
      let { navList } = this;
      //获取首页菜单栏
      for (var i in navList) {
        if (navList[i].name === "关于盘古") {
          // 获取企业介绍
          for (var j in navList[i].children) {
            if (navList[i].children[j].name === "企业介绍") {
              that.getList(navList[i].children[j].code, "企业介绍", "nav");
            }
          }
        } else if (navList[i].name === "营销案例") {
          that.getList(",,", "营销案例", "list");
        }
      }
    },
    //获取相对应的新闻列表
    getList(code, name, val) {
      let that = this;
      let params;
      params = {
        pageIndex: 1,
        pageSize: val === "nav" ? 10 : 20,
        newsCode: val === "nav" ? code : "",
        typeCode: val === "nav" ? "" : code,
      };
      this.$api.sysNewsList(params).then((res) => {
        if (res.code === 0) {
          switch (name) {
            case "营销案例":
              that.exampleList = res.data.list;
              setTimeout(() => {
                this.setSwiper();
              }, 1000);
              break;

            default:
              break;
          }
        }
      });
    },
    // 前往案例详情
    toExampleDetail(val) {
      const parentNav = this.navList.find((ele) => ele.code == this.xwCode);
      this.$router.push(`/exampleDetail_${parentNav.id}/${val.id}`);
    },
    // 首页所有 查看更多
    getMore(path) {
      this.$router.push(path);
    },
    // 全品类 修改样式
    onSelect(item, child) {
      item.children.forEach((ele) => {
        ele.active = false;
      });
      child.active = true;
    },
    // 全品类 左右箭头move
    typeMove(type) {
      const nowWindowWidth = window.innerWidth;
      let showCount = nowWindowWidth < 1400 ? 3 : 4; // 默认页面展示4个品类，<1400时展示3个品类
      if (this.bussinessList.length <= showCount) return;
      let content = document.getElementsByClassName("content")[0];
      let len = content.children.length;
      let left;
      if (type == "prev") {
        // 向左
        if (this.moveIndex == 0) {
          this.moveIndex = len - showCount;
          left = -(len - showCount) * 314 + "px";
        } else {
          this.moveIndex--;
          left = -(this.moveIndex * 314) + "px";
        }
      } else {
        // 向右
        if (this.moveIndex == len - showCount) {
          this.moveIndex = 0;
          left = "0";
        } else {
          this.moveIndex++;
          left = -(this.moveIndex * 314) + "px";
        }
      }
      content.style.left = left;
    },
    // 首页公司介绍 轮播 切换按钮
    moduleMove(type) {
      this.moduleOpc = true;
      let count = Math.ceil(this.introList.length / 3);
      if (type == "prev") {
        // 向左
        this.nowIntroIdx =
          this.nowIntroIdx == 0 ? count - 1 : this.nowIntroIdx - 1;
      } else {
        // 向右
        this.nowIntroIdx =
          this.nowIntroIdx == count - 1 ? 0 : this.nowIntroIdx + 1;
      }
      setTimeout(() => {
        this.moduleOpc = true;
        this.nowIntroList = this.introList.slice(
          3 * this.nowIntroIdx,
          3 * (this.nowIntroIdx + 1)
        );
        this.moduleOpc = false;
      }, 500);
    },
    // 根据 全品类 分级业务获取跳转到详情页
    getBusinessInfo(val, parent, idx) {
      const firstNav = this.navList.find((ele) => ele.code == this.ywCode);
      if (idx == 3) {
        // 查看更多
        this.$router.push(
          `/business_${firstNav.id}/${parent.id}?sub=${parent.children[0].code}`
        );
        return;
      }
      this.$router.push(
        `/business_${firstNav.id}/${parent.id}?sub=${val.code}`
      );
    },
    swipeChange(idx) {
      this.currentSwipe = idx;
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  // 灰色滤镜 (让盒子整体变灰)
  // filter: progid:DXImageTransform.Microsoft.BasicImage(graysale=1);
  // -webkit-filter: grayscale(100%);
}
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}
// swiper轮播
#certify {
  width: 1200px;
  margin: 35px auto 0;
  position: relative;
  overflow: hidden;

  .swiper-container {
    padding-bottom: 60px;
  }
  .swiper-slide {
    width: 280px;
    height: 310px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    overflow: hidden;
    background-color: #fff;
    transition: 0.5s;

    img {
      display: block;
      width: 100%;
      height: 165px;
    }
    .index-example-content-item-title {
      font-size: 14px;
      color: #000;
    }
    .index-example-txt {
      font-size: 12px;
      color: #000;
    }
  }
  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: $theme;
  }
}

// title 公共样式
.title-all {
  font-size: 40px;
  font-weight: bold;
  margin: 30px auto 60px;
}
.title-center {
  width: 100%;
  text-align: center;
  position: relative;
  &::after {
    display: block;
    content: "";
    width: 50px;
    height: 3px;
    position: absolute;
    left: 50%;
    bottom: -15px;
    transform: translateX(-50%);
    background-color: $theme;
  }

  .subtitle-all {
    font-size: 20px;
    color: grey;
    font-weight: lighter;
    letter-spacing: 3px;
    padding: 10px 0;
  }
}
.title-left {
  width: 1200px;
  padding-bottom: 20px;
  text-align: left;
  border-bottom: 1px solid #c8c6c6;
  position: relative;

  .subtitle {
    color: #eae9e7;
    font-size: 16px;
    margin-left: 20px;
    font-weight: 600;

    span {
      font-size: 20px;
      margin-left: 5px;
    }
  }

  .more {
    font-size: 14px;
    user-select: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    letter-spacing: 1px;

    &:hover {
      cursor: pointer;
      color: $theme !important;
    }
    &:active {
      transform: scale(0.9);
    }
  }
}

// 主样式
.index {
  background-color: #eeeef0;
  user-select: none;
  font-size: 16px;

  .index-banner {
    position: relative;
    width: 100%;

    .video {
      width: 100%;
      display: block;

      &::-webkit-media-controls {
        display: none !important;
      }
    }

    #swiper_banner {
      width: 100%;
      height: 80vh;
      position: relative;
      overflow: hidden;

      :deep(.banner-bullet) {
        width: 40px;
        height: 3px;
        display: inline-block;
        background-color: rgb(255 255 255 / 30%);
        margin: 0 5px;
        border-radius: 10px;
      }
      :deep(.banner-bullet-active) {
        background-color: $theme;
      }

      .swiper-slide {
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 20%;
          background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.5),
            transparent
          );
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .introImg {
    width: 100%;
    height: auto;
  }
  .index-body::before {
    content: "";
    display: table;
  }

  .index-descript {
    width: 1200px;
    margin: 0 auto 84px;
    background-color: #fff;
    .index-descript-title {
      white-space: pre-wrap;
      font-family: "hanSans";
      text-align: justify;
      letter-spacing: 0.5px;
      font-weight: 500;
      line-height: 40px;
      font-size: 20px;
      color: grey;
      padding: 22px 0 74px;
      user-select: text;
      text-indent: 2em;

      img {
        margin-top: 7px;
        vertical-align: top;
      }
      .modulep {
        color: #b9b9b9;
        font-size: 14px;
        position: relative;
      }
      .showmore {
        position: absolute;
        right: 0;
        cursor: pointer;
        color: #999999;
        &:hover {
          color: $theme;
        }
      }
    }
    .index-descript-content {
      width: 1440px;
      position: relative;
      margin-left: -120px;

      &:hover {
        .prev,
        .next {
          opacity: 1;
        }
      }

      .descript-wrap {
        width: 1200px;
        margin: 0 auto;
        transition: opacity 0.5s;
      }
      .opacity {
        opacity: 0;
      }

      .index-descript-content-item {
        width: 384px;
        height: 250px;
        display: inline-block;
        margin-right: 24px;
        box-shadow: 2px 5px 5px 0px rgba(236, 235, 235, 0.3);
        position: relative;

        &:last-child,
        &:nth-child(3) {
          margin-right: 0;
        }
        &:hover {
          .index-descript-content-title {
            color: #fff;
            &::after {
              opacity: 1;
              width: 100%;
            }
          }
          .show {
            transform: scale(1.024);
          }
        }
        &:hover::after {
          opacity: 1;
        }
        .post {
          height: 100%;
          width: 100%;
          overflow: hidden;
        }
        .show {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s;
        }
        .index-descript-content-title {
          font-weight: bold;
          color: #fff;
          font-size: 30px;
          height: 40px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 102;
          &::after {
            content: " ";
            width: 100%;
            height: 2px;
            background: #fff;
            bottom: -2px;
            left: 0;
            position: absolute;
            opacity: 0;
            transition: transform 0.3s ease-out;
            transition: all 0.3s;
          }
        }
        .index-descript-content-tip {
          width: calc(100% - 20px);
          margin: 5px 10px 20px 10px;
          font-size: 14px;
          height: 20px;
          color: #8e8e8e;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .index-descript-btn {
          width: calc(100% - 20px);
          margin: 0 10px 15px 10px;
          line-height: 38px;
          text-align: center;
          cursor: pointer;
          color: $theme;
          font-size: 16px;
          border: 1px solid #4087f2;
          border-radius: 5px;
          transition: all 0.3s;
          &:hover {
            color: #fff;
            background-color: $theme;
          }
        }
      }

      .prev,
      .next {
        width: 55px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s;

        &:hover {
          cursor: pointer;
        }
      }
      .prev {
        left: 0;
      }
      .next {
        right: 0;
      }
    }
  }
  .index-domain {
    text-align: center;
    background-color: #eeeef0;
    padding-bottom: 84px;

    &::before {
      content: "";
      display: table;
    }

    .title {
      font-size: 48px;
      font-weight: bold;
      margin: 30px 0;
      letter-spacing: 5px;
      position: relative;

      &::after {
        display: block;
        content: "";
        width: 50px;
        height: 3px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #02458c;
      }
    }

    img {
      transition: all 0.5s;
      margin-bottom: 20px;
    }
  }

  .index-system {
    text-align: center;
    padding-bottom: 84px;

    &::before {
      content: "";
      display: table;
    }

    .title {
      font-size: 48px;
      font-weight: bold;
      margin: 30px 0;
      letter-spacing: 5px;
      position: relative;

      &::after {
        display: block;
        content: "";
        width: 50px;
        height: 3px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #02458c;
      }
    }

    img {
      width: 1000px;
      transition: all 2s;
      margin: 80px 0 50px;
    }

    .system-more {
      width: 100px;
      padding: 12px 20px;
      border: 1px solid #dcdfe6;
      color: $default;
      border-radius: 10px;
      margin: 0 auto;
      font-size: 14px;
      text-align: center;
      user-select: none;

      &:hover {
        background-color: $theme;
        color: #fff;
        border-color: transparent;
        cursor: pointer;
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }

  .index-scale {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 84px;

    .scale {
      text-align: center;
      font-size: 35px;
      color: #333;
      font-weight: bold;

      .box {
        font-size: 16px;
        font-weight: normal;
        color: #666666;
        position: relative;

        .num {
          font-family: "hanSans";
          font-size: 48px;
          color: #183668;
        }
        .number {
          white-space: nowrap;
          position: absolute;
          top: -60px;
          left: 50%;
          transform: translateX(-50%);
        }
        .topUnit {
          vertical-align: top;
          font-size: 22px;
        }
      }

      p {
        margin-bottom: 30px;
        position: relative;

        &::after {
          display: block;
          content: "";
          width: 50px;
          height: 3px;
          position: absolute;
          left: 50%;
          bottom: -15px;
          transform: translateX(-50%);
          background-color: $theme;
        }
      }

      .left-top,
      .right-top,
      .center,
      .left-bot,
      .right-bot {
        position: absolute;
      }
      .left-top,
      .right-top {
        top: 100px;
      }
      .left-bot,
      .right-bot {
        bottom: 75px;
      }
      .left-top,
      .left-bot {
        left: 75px;
      }
      .right-top,
      .right-bot {
        right: 75px;
      }
      .center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .range {
      text-align: center;
      font-size: 35px;
      color: #333;
      font-weight: bold;
      position: relative;

      p {
        margin-bottom: 30px;
        position: relative;
        &::after {
          display: block;
          content: "";
          width: 50px;
          height: 3px;
          position: absolute;
          left: 50%;
          bottom: -15px;
          transform: translateX(-50%);
          background-color: $theme;
        }
      }
    }
  }
  .index-example {
    width: 100%;
    padding-bottom: 84px;
    background-image: url("../assets/index/homepagebg6.jpeg");
    background-size: cover;

    &::before {
      content: "";
      display: table;
    }

    .index-example-title {
      color: #fff;
    }

    .index-example-content {
      display: block;
      width: 1200px;
      margin: 0px auto;
      .index-example-content-item {
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
        &:last-child,
        &:nth-child(3) {
          margin-right: 0;
        }
        &:hover {
          img {
            transform: scale(1.024);
          }
          .index-example-content-item-title {
            h4 {
              position: absolute;
              bottom: 80px;
            }
            .index-example-txt {
              opacity: 1;
              position: absolute;
              bottom: 60px;
            }
          }
        }
        img {
          transition: all 0.3s;
        }
        .index-example-content-item-title {
          height: 100%;
          width: calc(100% - 40px);
          padding: 0 20px;
          // line-height: 150px;
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          position: absolute;
          bottom: 0;
          transition: all 0.3s;
          h4 {
            width: calc(100% - 40px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: absolute;
            bottom: 80px;
            transition: all 0.3s;
          }
          .index-example-txt {
            display: block;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            width: calc(100% - 40px);
            margin-top: 9px;
            line-height: 1.8;
            font-size: 16px;
            color: #fff;
            opacity: 0;
            // transform: translate(0px, 15px);
            transition: all 0.3s;
            position: absolute;
            bottom: 50px;
          }
        }
        .index-example-codeData {
          width: 280px;
          display: flex;
          position: absolute;
          bottom: 0;

          .val {
            width: 50%;
            height: 60px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .value {
              font-weight: bold;
              color: rgb(2, 69, 140);
            }
            .title {
              font-size: 14px;
            }
          }
        }
      }
    }
    .example-more {
      width: 100px;
      padding: 12px 20px;
      border: 1px solid #dcdfe6;
      color: #fff;
      text-align: center;
      border-radius: 10px;
      margin: 30px auto 0;
      font-size: 14px;
      user-select: none;

      &:hover {
        // background-color: $theme;
        border-color: $theme;
        color: $theme;
        cursor: pointer;
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }
  .index-product {
    width: 100%;
    // height: 380px;
    padding-bottom: 84px;
    .index-product-content {
      width: fit-content;
      margin: 0px auto;
      padding: 20px;
      position: relative;
      // box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
      .index-product-tab {
        display: block;
        width: 100%;
        height: 80px;
        .index-product-tab-list {
          display: block;
          height: 100%;
          background-color: #f9f9f9;
          .index-product-tab-list-li {
            display: block;
            float: left;
            width: calc(100% / 7);
            height: 100%;
            line-height: 80px;
            font-weight: bold;
            color: $default;
            text-align: center;
            opacity: 1;
            position: relative;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background-color: $theme;
              color: #fff;
            }
          }
          .activeli {
            background-color: $theme;
            color: #fff;
          }
        }
      }
      .index-product-main {
        display: block;
        width: 100%;
        .index-product-main-logo {
          width: 20%;
          display: inline-block;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            transform: scale(1.024);
          }
        }
      }

      .wrap {
        width: 1210px;
        height: 400px;
        overflow: hidden;
        margin: 0 auto;
        position: relative;

        .content {
          width: fit-content;
          height: 100%;
          white-space: nowrap;
          display: flex;
          position: absolute;
          left: 0;
          transition: all 0.3s;
        }

        .list {
          width: calc(1050px / 4);
          height: 385px;
          border: 1px solid #e6e6e6;
          margin-right: 50px;
          text-align: center;

          .title {
            font-size: 19px;
            padding: 15px 0;
            border-bottom: 2px solid #e6e6e6;
            color: #0a56aa;
            font-weight: bold;
          }
          .desc {
            padding: 10px;
            color: #686868;
            font-size: 14px;
            border-bottom: 1px solid #e1e1e2;
          }
          .part {
            width: 100%;

            .item {
              padding: 5px 0;
              border-bottom: 1px solid #e1e1e2;
              transition: all 0.2s;
              color: #666;

              &:hover {
                cursor: pointer;
              }

              .item-more {
                height: 45px;
                line-height: 45px;
                font-size: 30px;
              }

              .icon {
                width: 45px;
                height: 45px;
                vertical-align: middle;
              }
            }
            .active {
              padding: 15px 0;
              border: 1px solid $theme !important;
              background-color: #f6f6f7;
            }
          }
        }
      }
    }
    .product-more {
      width: 100px;
      padding: 12px 20px;
      border: 1px solid #dcdfe6;
      color: $default;
      border-radius: 10px;
      margin: 0 auto;
      font-size: 14px;
      text-align: center;
      user-select: none;

      &:hover {
        background-color: $theme;
        color: #fff;
        border-color: transparent;
        cursor: pointer;
      }
      &:active {
        transform: scale(0.9);
      }
    }
    .prev,
    .next {
      width: 55px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: none;

      &:hover {
        cursor: pointer;
      }
    }
    .prev {
      left: -100px;
    }
    .next {
      right: -100px;
    }
  }

  .index-new {
    padding-bottom: 84px;

    &::before {
      content: "";
      display: table;
    }
    .index-new-title {
      margin-bottom: 0;
    }

    .more {
      color: #333;
    }

    .index-new-content {
      width: 1200px;
      margin: 20px auto;

      .type-box {
        width: 480px;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        text-align: center;

        .li {
          width: 160px;
          height: 35px;
          line-height: 35px;
          font-weight: bold;
          font-size: 22px;

          &:hover {
            cursor: pointer;
          }
        }
        .active {
          color: $theme;
        }
      }
      .content-box {
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;

        .new {
          width: 590px;
          height: 190px;
          display: flex;
          background-color: #fdfcfc;
          border: 1px #d7d5d5 solid;
          margin-bottom: 16px;
          box-sizing: border-box;

          &:hover {
            cursor: pointer;
            border-color: $theme;

            img {
              transform: translateX(-50%) scale(1.2) !important;
            }
          }
          &:nth-child(2n-1) {
            margin-right: 16px;
          }

          .left {
            width: 270px;
            height: 100%;
            flex-shrink: 0;
            overflow: hidden;
            position: relative;

            img {
              min-width: 100%;
              height: 100%;
              display: block;
              transition: all 1s;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .right {
            .title {
              font-size: 18px;
              color: #666666;
              font-weight: bold;
              margin-left: 20px;
              margin-top: 20px;
            }
            .time {
              font-size: 13px;
              color: #999999;
              margin-left: 20px;
              margin-top: 5px;
            }
            .desc {
              width: 280px;
              height: 65px;
              font-size: 14px;
              color: #666666;
              margin-left: 20px;
              margin-top: 20px;
              line-height: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      .message {
        margin-top: 40px;
        text-align: center;
      }
    }
  }

  .index-link {
    width: 1200px;
    margin: 0 auto 40px;

    .div3_title {
      margin-top: 0px;
      height: 90px;

      .div3_title1 {
        font-size: 36px;
        color: #666666;
        line-height: 36px;
      }
      .themeLine {
        width: 74px;
        height: 2px;
        background-color: #3c79f3;
        margin-top: 25px;
      }
      .line {
        width: 100%;
        height: 1px;
        background-color: #c8c6c6;
      }
    }

    .div3_li {
      float: left;
      margin-right: 15px;
      font-size: 18px;

      a {
        color: #666666;

        &:hover {
          color: $theme;
        }
      }
    }
    ul:after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .index-message {
    min-height: 100px;
    background-color: #eeeef0;

    .content {
      width: 1200px;
      margin: 0 auto;
      padding: 30px 0;
      display: flex;
      align-items: center;

      p {
        font-size: 28px;
        color: #333;
        margin-right: 20px;
      }
      .ipt-frame {
        // width: 1000px;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .ipt,
        .textarea {
          background-color: #f3f2f2;
          border: 1px solid #d7d5d5;
          font-size: 12px;
        }

        .ipt {
          width: 24%;
          height: 35px;
          text-indent: 1em;
        }
        .textarea {
          width: 100%;
          height: 60px;
          margin-top: 10px;
          text-indent: 1em;
          padding: 8px 0;
          box-sizing: border-box;
        }
      }
      .submit {
        height: fit-content;
        white-space: nowrap;
        padding: 10px 40px;
        color: #fff;
        background-color: $theme;
        border: none;
        outline: none;
        margin-left: 20px;

        &:hover {
          cursor: pointer;
        }
        &:active {
          transform: scale(0.9);
        }
      }
    }
  }
}

// 移动端样式
.mobile-title {
  width: fit-content;
  margin: pxToRem(20) auto;
  padding-bottom: pxToRem(15);
  position: relative;
  text-align: center;

  h2 {
    color: #666;
    font-size: pxToRem(16);
  }
  .sub {
    color: #999;
    font-size: pxToRem(14);
  }
  &::after {
    content: "";
    display: block;
    width: 30%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: $theme;
  }
}
.mobile-more {
  width: 100%;
  padding: pxToRem(5) 0;
  margin-top: pxToRem(10);
  text-align: center;
  background-color: $theme;
  color: #fff;
  font-size: pxToRem(14);
}

.mobile-index {
  font-size: 16px;
  margin-bottom: pxToRem(10);

  .mobile-service {
    text-align: center;
    background-color: #fff;
    padding-bottom: pxToRem(20);

    &::before {
      content: "";
      display: table;
    }

    .service-body {
      position: relative;

      .wrap {
        width: 80vw;
        margin: 30px auto 0;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .busList {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .item {
        width: pxToRem(80);
        margin-bottom: 20px;

        .icon {
          width: pxToRem(35);
          height: pxToRem(35);
        }
        .text {
          font-size: pxToRem(11);
          transform: scale(0.8);
          // color: $theme;
        }
      }

      .prev,
      .next {
        width: pxToRem(20);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .prev {
        left: pxToRem(9);
      }
      .next {
        right: pxToRem(9);
      }
    }
  }

  .mobile-about {
    margin-top: pxToRem(10);
    background-color: #fff;
    &::before {
      content: "";
      display: table;
    }

    .about-body {
      padding-bottom: pxToRem(20);
      .desc {
        padding: 0 pxToRem(20);
        font-size: pxToRem(14);
        color: #666;
        text-align: justify;
        white-space: pre-wrap;
        text-indent: 2em;
      }

      .about-swipe {
        width: 90vw;
        margin: pxToRem(20) auto;
      }

      .van-swipe-item {
        width: 100%;
        display: inline-block;
        box-shadow: 2px 5px 5px 0px rgba(236, 235, 235, 0.3);
        position: relative;
        cursor: pointer;

        .el-image {
          display: block;
          height: pxToRem(220);
        }

        .index-descript-content-title {
          font-weight: bold;
          color: #fff;
          font-size: pxToRem(30);
          height: pxToRem(40);
          text-align: center;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 102;
          &::after {
            content: " ";
            width: 100%;
            height: 2px;
            background: #fff;
            bottom: -2px;
            left: 0;
            position: absolute;
            opacity: 0;
            transition: transform 0.3s ease-out;
            transition: all 0.3s;
          }
        }
      }

      .custom-indicator {
        position: absolute;
        right: 5px;
        bottom: 5px;
        padding: 2px 5px;
        font-size: pxToRem(12);
        background: rgba(255, 255, 255, 0.8);
      }

      .about-num {
        padding: 0 pxToRem(20);
        margin-top: pxToRem(40);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .num-box {
          font-family: "hanSans";
          width: 45%;
          height: pxToRem(60);
          border: 1px solid $theme;
          margin-bottom: pxToRem(40);
          position: relative;

          .text {
            text-align: center;
            font-size: pxToRem(16);
            line-height: pxToRem(80);
          }
          .number {
            width: pxToRem(130);
            color: $theme;
            font-size: pxToRem(34);
            text-align: center;
            position: absolute;
            top: pxToRem(-25);
            left: 50%;
            transform: translateX(-50%);
            background-color: #fff;

            .unit {
              font-size: pxToRem(14);
              margin-left: 3px;
            }
            .topUnit {
              vertical-align: top;
            }
          }
        }
      }
    }
  }

  .mobile-example {
    padding: 0 pxToRem(20) pxToRem(20);
    margin-top: pxToRem(10);
    background-color: #fff;

    &::before {
      content: "";
      display: table;
    }

    .example-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .example-item {
        width: pxToRem(160);
        margin-bottom: pxToRem(10);
        border: 1px #d7d7d8 solid;
        border-radius: 5px;
        overflow: hidden;

        .item-title {
          width: 100%;
          line-height: pxToRem(22);
          box-sizing: border-box;
          padding: 0 pxToRem(5);
          font-size: pxToRem(14);
          border-bottom: 1px #d7d7d8 solid;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      img {
        width: 100%;
        height: pxToRem(90);
      }

      .codeData {
        display: flex;

        .val {
          width: 50%;
          height: pxToRem(50);
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: pxToRem(10);

          .value {
            font-weight: bold;
            color: $theme;
          }
        }
      }
    }
  }

  .mobile-news {
    background-color: #fff;
    padding: 0 pxToRem(20) pxToRem(20);
    margin-top: pxToRem(10);

    &::before {
      content: "";
      display: table;
    }
    .news-body {
      .van-tabs__wrap {
        height: pxToRem(44);
      }
      .van-tab {
        font-size: pxToRem(14);
        line-height: normal;
      }
      .van-tabs__line {
        width: pxToRem(40);
        background-color: $theme;
      }
      .news {
        margin-top: pxToRem(20);
      }
      .new {
        width: 100%;
        display: flex;
        background-color: #fdfcfc;
        border-bottom: 1px #d7d5d5 solid;
        margin-bottom: pxToRem(16);

        &:nth-child(2n-1) {
          margin-right: 16px;
        }

        .left {
          width: pxToRem(140);
          flex-shrink: 0;
          display: flex;
          align-items: center;

          img {
            width: 100%;
          }
        }
        .right {
          flex-grow: 1;
          margin-left: pxToRem(10);
          display: flex;
          align-items: center;

          .title {
            width: 100%;
            font-size: pxToRem(14);
            color: #666666;
            font-weight: bold;
            margin-top: pxToRem(10);
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
          }
          .time {
            font-size: pxToRem(13);
            color: #999999;
            margin-top: 5px;
          }
          .desc {
            font-size: pxToRem(14);
            color: #666666;
            margin-top: pxToRem(10);
            line-height: pxToRem(22);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  .organization {
    background-color: #fff;
    margin-top: pxToRem(10);

    &::before {
      content: "";
      display: table;
    }
  }
}

@media (max-width: 1500px) {
  .index-product-content .prev {
    left: -60px !important;
  }
  .index-product-content .next {
    right: -60px !important;
  }
  .index .index-descript .index-descript-content {
    width: 1200px;
    margin: 0 auto;

    .prev {
      left: -60px;
    }
    .next {
      right: -60px;
    }
  }
}
@media (max-width: 1400px) {
  .index .index-product .index-product-content .wrap {
    width: 895px;
  }
  .index {
    .index-product {
      .prev,
      .next {
        display: block;
      }
    }
    .index-domain img {
      width: 1000px;
    }
    .index-descript {
      width: 1000px;

      .index-descript-content {
        width: 100%;

        .descript-wrap {
          width: 100%;

          .index-descript-content-item {
            width: 330px;
            margin-right: 0;

            & + .index-descript-content-item {
              margin-left: 5px;
            }
          }
        }
      }
    }
    .index-scale {
      width: 1000px;
      margin: 0 auto;
      justify-content: space-between;
    }
    .title-left,
    #certify {
      width: 1000px;
    }
    .index-new .index-new-content {
      width: 1000px;

      .content-box {
        width: 100%;

        .new {
          width: 490px;
          height: 160px;

          .left {
            width: 220px;
          }
          .right {
            flex-grow: 1;
            .title,
            .time,
            .desc {
              margin-left: 10px;
            }
            .desc {
              width: auto;
            }
          }
        }
      }
    }
    .index-link,
    .index-message .content {
      width: 1000px;
    }
  }
}

@media (max-width: 750px) {
  .index-descript {
    width: calc(100% - 40px) !important;
    padding: 0 20px 50px 20px !important;
    background-color: #fafafb !important;
    .index-descript-title {
      padding: 15px 0 !important;
      font-size: 16px !important;
      .modulep {
        font-size: 10px !important;
      }
      .showmore {
        display: none;
      }
      img {
        width: 13px !important;
        height: 15px !important;
        margin-top: 4px !important;
      }
    }
    .index-descript-content {
      .index-descript-content-item {
        width: calc(50% - 7px) !important;
        height: auto !important;
        display: inline-block;
        margin: 0 10px 10px 0 !important;
        border: 1px solid #ececec;
        box-shadow: 2px 5px 5px 0px rgba(236, 235, 235, 0.3);
        &:nth-child(even) {
          margin: 0 !important;
        }
        .show {
          height: 27vw;
        }
        .index-descript-content-title {
          margin-top: 5px;
          font-size: 14px !important;
          font-weight: bold;
          height: 20px !important;
          line-height: 20px !important;
          color: $default;
          text-align: center;
        }
        .index-descript-content-tip {
          width: calc(100% - 20px);
          margin: 5px 10px 10px 10px !important;
          font-size: 12px !important;
          height: 20px;
          color: #8e8e8e;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .index-descript-btn {
          width: calc(100% - 20px);
          margin: 0 10px 10px 10px !important;
          line-height: 20px !important;
          text-align: center;
          cursor: pointer;
          color: #fff !important;
          background-color: $theme;
          font-size: 12px !important;
          border: none;
          border-radius: 5px;
          &:active {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .index .index-banner #swiper_banner {
    height: 30vh;
  }
}
</style>

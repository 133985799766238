<template>
  <van-tabbar z-index="20" v-model="activeId" @change="onChange">
    <van-tabbar-item name="/" icon="wap-home">首页</van-tabbar-item>
    <van-tabbar-item name="phone" icon="phone"
      ><a class="link" href="tel:400-6222-028">电话咨询</a></van-tabbar-item
    >
    <van-tabbar-item name="/contact_41?type=consult" icon="manager"
      >联系我们</van-tabbar-item
    >
  </van-tabbar>
</template>
<script>
export default {
  data() {
    return {
      activeId: this.$route.path,
    };
  },
  watch: {
    $route(newer) {
      this.activeId = newer.path;
    },
  },
  methods: {
    onChange(name) {
      if (name != "phone") {
        this.$router.push(name);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}
.link {
  color: #646566;
}

.van-tabbar {
  height: pxToRem(50);
  .van-tabbar-item__text {
    font-size: pxToRem(12);
  }
}
</style>

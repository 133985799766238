import request from "./request";

export default {
  //查询已发布的banner列表
  sysBannerList(form) {
    return request({
      url: `web/sysBanner/front`,
      method: "get",
      params: form,
    });
  },
  //查询已发布的首页图片列表
  sysBannerindexImg(form) {
    return request({
      url: `web/sysBanner/front/indexImg`,
      method: "get",
      params: form,
    });
  },
  //根据一级查询2，3级code
  findByTopCode(form) {
    return request({
      url: `web/sys-dictionary/front/findByTopCode`,
      method: "get",
      params: form,
    });
  },
  // 新闻配置code查询
  newCodeDic() {
    return request({
      url: `web/sys-dictionary/front/newCodeDic`,
      method: "get",
    });
  },
  /**
   * 查询首页显示字典
   * @returns { flag:1 ? 可选参数 若传参则为前台展示,用于区分 publish }
   */
  newsDic(params) {
    return request({
      url: `web/sys-dictionary/front/newsDic`,
      method: "get",
      params,
    });
  },
  //查询类型配置
  typeDic(params) {
    return request({
      url: `web/sys-dictionary/front/typeDic`,
      method: "get",
      params,
    });
  },
  //修改或新增字典
  sysDictionary(form) {
    return request({
      url: `web/sys-dictionary/system`,
      method: "post",
      data: form,
    });
  },

  /**
   * 根据父级结构查询
   * @param {*} form  新增 { flag:1 ? 可选参数 若传参则为前台展示,用于区分 publish }
   * @returns
   */
  searchDictionary(form) {
    return request({
      url: `web/sys-dictionary/system/listByParentCode`,
      method: "get",
      params: form,
    });
  },

  /**
   * 根据业务介绍查询所有子集节点
   * @param {*} params 新增 { flag:1 ? 可选参数 若传参则为前台展示,用于区分 publish }
   * @returns
   */
  searchByParentCode(params) {
    return request({
      url: `web/sys-dictionary/front/listAllChildrenByParentCode`,
      method: "get",
      params,
    });
  },

  //根据业务介绍 子集节点 查询内部具体内容
  getBusinessByCode(params) {
    return request({
      url: `web/sys-dictionary/front/getBusinessByCode`,
      method: "get",
      params,
    });
  },
  //删除字典
  delDictionary(form) {
    return request({
      url: `web/sys-dictionary/system/` + form,
      method: "delete",
    });
  },
  // 获取所有友情链接
  sysFriendLinkList(form) {
    return request({
      url: `web/sysFriendLink/front`,
      method: "get",
      params: form,
    });
  },
  //增加或者修改留言
  addFeed(data) {
    return request({
      url: `web/sys-feedback/add`,
      method: "post",
      data,
    });
  },
  //获取所有联系我们
  sysConcatUsList(form) {
    return request({
      url: `web/sysConcatUs/front`,
      method: "get",
      params: form,
    });
  },
  // 年限
  sysHistoryyears(form) {
    return request({
      url: `web/sys-history/front/years`,
      method: "get",
      params: form,
    });
  },
  // 年限发生事件
  syslistHistory(form) {
    return request({
      url: `web/sys-history/front/listHistory`,
      method: "get",
      params: form,
    });
  },
  // 获取资质/荣誉
  sysHonorList(form) {
    return request({
      url: `web/sys-honor/front`,
      method: "get",
      params: form,
    });
  },
  // 查询招聘管理
  sysJobList(form) {
    return request({
      url: `web/sysJob/front`,
      method: "get",
      params: form,
    });
  },
  // 分页查询所有新闻
  sysNewsList(form) {
    return request({
      url: `web/sysNews/front`,
      method: "get",
      params: form,
    });
  },
  // 新闻详情
  searchNewsinfo(form) {
    return request({
      url: `web/sysNews/front/` + form,
      method: "get",
    });
  },
  //查询公司列表
  syscompanysList(form) {
    return request({
      url: `web/sys-company/front`,
      method: "get",
      params: form,
    });
  },
  //查询公司列表(新)
  getCompany(id) {
    return request({
      url: `web/sys-company-about/frontCompany/${id}`,
      method: "get",
    });
  },
  // 查询公司组织架构
  syscompanyaboutList(form) {
    return request({
      url: `web/sys-company-about/front`,
      method: "get",
      params: form,
    });
  },
  //查询配置
  searchwebConfig() {
    return request({
      url: `web/sys-web-config/front`,
      method: "get",
    });
  },
  //新增或者修改配置
  syswebConfig(form) {
    return request({
      url: `web/sys-web-config/system`,
      method: "post",
      data: form,
    });
  },
  // 给指定企业邮箱投递简历
  sendMail(params) {
    return request({
      url: `web/sysJob/front/sendMail`,
      method: "get",
      params,
    });
  },
};

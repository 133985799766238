<!-- 普通分类导航 -->
<template>
  <div class="navBar">
    <div class="type-box" v-if="tabNavList.length > 0">
      <ul class="type-nav-ul">
        <li
          :class="[activeCode === item.code ? 'active' : '', 'type-nav-li']"
          v-for="item in tabNavList"
          :key="item.id"
          @click="changeNav(item)"
        >
          <a>{{ item.name }}</a>
        </li>
      </ul>
      <ul class="subNav" v-if="thirdNavlist.length > 0">
        <li
          :class="[
            subActiveCode === item.code ? 'activewords' : '',
            'child-nav-li',
          ]"
          v-for="item in thirdNavlist"
          :key="item.id"
          @click="changechildNav(item.code)"
        >
          <a>{{ item.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    activeCode: {
      type: String,
      default: "",
    },
    subActiveCode: {
      type: String,
      default: "",
    },
    tabNavList: {
      type: Array,
      default: () => [],
    },
    thirdNavlist: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
  mounted() {},
  methods: {
    changeNav(item) {
      this.$emit("changeNav", item);
    },
    changechildNav(code) {
      this.$emit("changechildNav", code);
    },
  },
};
</script>

<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}
.navBar {
  background: #eeeef0;
}
.type-box {
  width: var(--frame-width);
  margin: 0 auto;
  background-color: #fff;
  line-height: 60px;

  --frame-width: 1500px;

  .type-nav-ul {
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    justify-content: center;
  }
  .type-nav-li {
    padding: 0 20px;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #02458c;
    }
  }
  .active {
    color: rgba(2, 69, 140, 0.7);
    position: relative;

    &::before {
      display: block;
      content: "";
      height: 3px;
      width: 50%;
      background: rgba(2, 69, 140, 0.7);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      z-index: 2;
      opacity: 1;
    }
  }

  .subNav {
    display: flex;
    justify-content: center;
    line-height: 60px;
    background-color: #fff;
    font-size: 14px;
    border-bottom: 1px solid #e9e9e9;
    flex-wrap: nowrap;
    overflow-x: auto;

    .child-nav-li {
      padding: 0 20px;
      flex-shrink: 0;

      &:hover {
        cursor: pointer;
        color: #02458c;
      }
    }
    .activewords {
      color: #02458c;
      font-weight: bold;
      position: relative;
    }
  }
}
@media (max-width: 1600px) {
  .type-box {
    --frame-width: 1200px;

    .type-nav-li {
      font-size: 14px;
    }
  }
}

@media (max-width: 1300px) {
  .type-box {
    --frame-width: 1000px;
  }
}

@media (max-width: 1200px) {
  .type-box {
    line-height: 30px;
    --frame-width: 100%;

    .type-nav-ul {
      justify-content: start;
      white-space: nowrap;
      overflow-x: scroll;
    }
  }
}

@media (max-width: 750px) {
  .type-box {
    .type-nav-ul {
      font-size: pxToRem(15);

      .type-nav-li {
        font-size: 16px;
        line-height: 50px;
      }
    }
    .subNav {
      font-size: pxToRem(14);
      line-height: pxToRem(40);
      justify-content: start;
    }
  }
}
</style>

<!-- 悬浮框 -->
<template>
  <div class="floating-box">
    <img
      :class="{ floating: true, move: !iconClass }"
      src="../assets/floating.jpg"
      alt="悬浮框"
      title="点击咨询"
      @click="consult"
    />
    <i
      :class="[
        'icon',
        iconClass ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left',
      ]"
      @click="close"
    ></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconClass: true,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 暖春计划
    consult() {
      if (this.isMobile == "pc") {
        setTimeout(() => {
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
          });
        }, 1000);
        if (this.$route.path !== "/") {
          this.$router.replace("/");
        }
      } else {
        this.$router.push("/contact_41?type=consult");
      }
    },
    close() {
      this.iconClass = !this.iconClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-box {
  position: fixed;
  left: 0;
  bottom: 15%;
  z-index: 999;

  .floating {
    width: 200px;
    transform: translateX(-100%);
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    cursor: pointer;

    &:hover + .icon {
      transform: translateX(0);
    }
  }
  .move {
    transform: translateX(0);
    transition: 0.5s;
  }
  .icon {
    font-size: 20px;
    background-color: #c1bfc0;
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 10px;
    bottom: -50px;
    border-radius: 50%;
    cursor: pointer;
    transform: translateX(-30px);
    transition: transform 0.5s;

    &:hover {
      transform: translateX(0);
    }
  }
}
@media (max-width: 750px) {
  .floating-box {
    .floating {
      width: 100px;
    }
    .icon {
      font-size: 12px;
      left: 5px;
      bottom: -40px;
    }
  }
}
</style>

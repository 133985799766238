<template>
  <div class="pangod-classify">
    <!-- 顶部 -->
    <BreadCrumb :routeArr="routeArr" v-if="!isMobile"></BreadCrumb>

    <!-- 内容 -->
    <!-- 除招贤纳士和联系我们的其他一级导航栏 -->
    <div class="pangod-classify-content">
      <CustomLoading v-show="vanLoading" />
      <!-- 富文本 渲染模式 -->
      <div class="detail-box">
        <div class="pangod-classify-detail">
          <div class="pangod-classify-html-title">
            {{ detail.title }}
          </div>
          <div class="pangod-classify-html-time">
            发布时间:
            {{ (detail.publishTime || detail.createTime) | handleTime }}
          </div>
        </div>
        <div class="pangod-classify-html" v-html="handleHTML"></div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/breadCrumb.vue";
import CustomLoading from "@/components/loading.vue";

import { Store } from "../store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      tabNavList: [],
      level: "",
      clickConfig: "", //访问方式 0:直接访问  1:查询列表
      params: {
        pageIndex: 1,
        pageSize: 12,
        newsCode: undefined,
        typeCode: undefined,
      },
      routeArr: [],
      vanLoading: false,
      detail: {},
      nowDic: {},
    };
  },
  components: {
    BreadCrumb,
    CustomLoading,
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
    // 富文本手机端兼容mobileContentStr字段
    handleHTML() {
      const { contentStr, mobileContentStr } = this.detail;
      if (this.isMobile) {
        return mobileContentStr || contentStr;
      } else {
        return contentStr;
      }
    },
  },
  mounted() {
    // 从其他路由到classify路由 或 首次进入网页为classify时
    this.setPageConfig();
  },
  methods: {
    // 构建页面参数
    setPageConfig() {
      const query = this.$route.params;
      // 父子id
      const { id, childId, newId } = query;
      out: for (const i in this.navList) {
        const ele = this.navList[i];
        if (ele.id == id) {
          // 获取二级分类
          // this.tabNavList = ele.children;
          // 两个参数
          for (const j in ele.children) {
            const child = ele.children[j];
            // 获取激活字典
            if (child.id == childId) {
              this.nowDic = child;
              this.getInfo(newId);
              break out;
            }
          }
        }
      }
    },
    // 获取内容
    getList(val) {
      this.params.pageIndex = 1;
      this.params.newsCode = val.code;

      this.vanLoading = true;

      //获取列表
      this.$api
        .sysNewsList(this.params)
        .then((res) => {
          if (res.code === 0) {
            this.detail = res.data.list[0] || {};
          }
        })
        .finally(() => {
          this.vanLoading = false;
        });
    },
    // 切换菜单
    changeNav(val) {
      this.$router.push(
        val.templateName + "_" + this.$route.params.id + "/" + val.id
      );
    },
    async getInfo(id) {
      //获取详情
      let res = await this.$api.searchNewsinfo(id);
      if (res.code === 0) {
        this.detail = res.data;
        window.scrollTo(0, 0);
      }
      const { id: firstId, childId } = this.$route.params;
      this.routeArr = [
        { name: "首页", path: "/" },
        {
          name: this.nowDic.name,
          path: this.nowDic.templateName + "_" + firstId + "/" + childId,
        },
        res.data.title,
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.pangod-classify {
  font-size: 16px;
  background-color: #eeeef0;

  --frame-width: 1500px;

  &::before {
    content: "";
    display: table;
  }

  .navBar {
    background: #eeeef0;

    .pangod-classify-nav {
      width: var(--frame-width);
      margin: 0 auto;
      background-color: #fff;
      line-height: 60px;

      .pangod-classify-nav-ul {
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: center;

        .pangod-classify-nav-li {
          padding: 0 20px;
          text-align: center;
          cursor: pointer;
        }
      }
      .active {
        color: rgba(2, 69, 140, 0.7);
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 50%;
          height: 3px;
          background: rgba(2, 69, 140, 0.7);
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          z-index: 2;
          opacity: 1;
        }
      }
    }
  }

  .pangod-classify-content {
    width: var(--frame-width);
    margin: 0 auto 20px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #ffffff;

    .van-loading {
      text-align: center;
    }

    .pangod-classify-detail {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #eee;
    }
    .pangod-classify-html-title {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
    }
    .pangod-classify-html-time {
      padding-top: 5px;
      font-size: 15px;
      color: #919797;
      text-align: center;
    }
    .pangod-classify-html {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      p {
        font-size: 16px;
        color: #191919;
        font-family: "PingFang SC", Arial, 微软雅黑, 宋体, simsun, sans-serif;
        line-height: 40px;
      }
    }
  }
}
@media (max-width: 1600px) {
  .pangod-classify {
    --frame-width: 1200px;
  }
  .pangod-classify .pangod-classify-content {
    padding: pxToRem(10) pxToRem(20);
    box-sizing: border-box;

    .pangod-classify-html {
      width: 100%;

      table {
        max-width: 100% !important;
        height: auto !important;
        margin: 0 auto;

        tr {
          height: auto !important;

          td {
            height: auto !important;
            padding-left: 0 !important;
          }
        }
      }
    }
  }
  .pangod-classify-nav-li {
    font-size: 14px;
  }
}
@media (max-width: 1300px) {
  .pangod-classify {
    --frame-width: 1000px;
  }
}
@media (max-width: 1200px) {
  .pangod-classify {
    --frame-width: 100%;
  }
  .pangod-classify .pangod-classify-content {
    box-sizing: border-box;
    margin-bottom: pxToRem(10);
  }

  .pangod-classify .navBar .pangod-classify-nav {
    line-height: 30px;

    .pangod-classify-nav-ul {
      justify-content: start;
      white-space: nowrap;
      overflow-x: scroll;
      padding: pxToRem(5) pxToRem(10);
    }
  }
}
@media (max-width: 1024px) {
  .pangod-classify .pangod-classify-content {
    .pangod-classify-html-title {
      font-size: 24px;
    }
    .pangod-classify-html-time {
      font-size: 14px;
    }
  }
}
@media (max-width: 750px) {
  .pangod-classify .navBar .pangod-classify-nav {
    line-height: pxToRem(40);

    .pangod-classify-nav-ul {
      font-size: pxToRem(15);
    }
  }
}
</style>

<template>
  <div class="loading-box">
    <section><span></span></section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #263238;
}
section {
  width: 100px;
  height: 60px;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 2px solid $theme;
}
span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border-top: 5px solid #9ec645;
  border-right: 5px solid transparent;
  animation: rotation 2s linear infinite;
}
span::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #183668;
  animation: rotation 1s linear infinite reverse;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

import { defineStore } from "pinia";

export const Store = defineStore("main", {
  state: () => {
    return {
      navList: [], // 导航条
      globalConfig: {}, // 全局配置
      linkList: [], // 公司/友情链接
      isMobile: false // 是否是手机端
    };
  },
  getters: {
    // 筛选类型为公司地址的链接
    companyLink() {
      return this.linkList.filter((ele) => ele.linkType == 1);
    },
    // 筛选类型为友情链接的链接
    friendLink() {
      return this.linkList.filter((ele) => ele.linkType == 0);
    }
  },
  actions: {
    setIsMobile(bol) {
      this.isMobile = bol;
    },
    // 存储导航条
    setNavList(data) {
      this.navList = data;
    },
    // 存储全局配置
    setConfig(data) {
      this.globalConfig = data;
    },
    // 存储友情链接
    setFriendLink(data) {
      this.linkList = data;
    }
  }
});

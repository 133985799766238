<template>
  <div id="app">
    <div class="loading" v-if="!isRouterAlive">
      <WaitBox :reqCount="reqCount" />
    </div>
    <div class="view-box" v-else>
      <FixBox v-if="false" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { _isMobile } from "./util/tool.js";
import WaitBox from "./components/waitBox.vue";
import FixBox from "./components/fixBox.vue";
export default {
  name: "App",
  data() {
    return {
      isRouterAlive: false,
      reqCount: 0,
      iconClass: true,
      isMobile: "",
    };
  },
  components: { WaitBox, FixBox },
  watch: {
    reqCount(newer) {
      if (newer == 3) {
        setTimeout(() => {
          this.isRouterAlive = true;
        }, 1500);
      }
    },
  },
  created() {
    this.changeFont(750, 1300);

    this.setConfig();

    this.isMobile = _isMobile() ? "mobile" : "pc";
    this.$store.setIsMobile(_isMobile());

    // 测试官网访问人流量
    this.union(window, document, "script", "_qha", 454498, false);
  },
  mounted() {},
  methods: {
    union(b, a, e, h, f, c, g, s) {
      b[h] =
        b[h] ||
        function () {
          (b[h].c = b[h].c || []).push(arguments);
        };
      b[h].s = !!c;
      g = a.getElementsByTagName(e)[0];
      s = a.createElement(e);
      s.src = "//s.union.360.cn/" + f + ".js";
      s.defer = !0;
      s.async = !0;
      g.parentNode.insertBefore(s, g);
    },
    setConfig() {
      this.isRouterAlive = false;

      this.$api.searchwebConfig().then((res) => {
        this.$store.setConfig(res.code == 0 ? res.data : {});
        this.reqCount++;
      });
      this.$api.newsDic({ flag: 1 }).then((res) => {
        this.$store.setNavList(res.code == 0 ? res.data : []);
        this.reqCount++;
      });
      this.$api.sysFriendLinkList().then((res) => {
        this.$store.setFriendLink(res.code == 0 ? res.data : []);
        this.reqCount++;
      });
    },
    /**
     * 适配函数 1rem == clientWidth / 7.5 + px
     * designWidth 基准尺寸
     * maxWidth 最大尺寸
     */
    changeFont(designWidth, maxWidth) {
      let doc = document,
        win = window;
      let docEl = doc.documentElement;
      let remStyle = document.createElement("style");
      //获取基准字体大小
      function refreshRem() {
        // let width = parseInt(window.screen.width); // uc有bug
        let width = docEl.getBoundingClientRect().width;
        if (!maxWidth) {
          maxWidth = 1366;
        }
        if (width > maxWidth) {
          width = maxWidth;
        }
        let rem = (width / designWidth) * 100;
        //得到的rem基准字体大小，这里乘以100是为了适配有的浏览器不识别小数，会导致设置字体无效。
        //设置根元素html的字体大小为基准字体大小，在css中每rem就是这个值的大小。
        remStyle.innerHTML = "html{font-size:" + rem + "px;} ";
      }
      refreshRem();
      if (docEl.firstElementChild) {
        docEl.firstElementChild.appendChild(remStyle);
      } else {
        let wrap = doc.createElement("div");
        wrap.appendChild(remStyle);
        doc.write(wrap.innerHTML);
        wrap = null;
      }
      /* 以下为在不同的时机重新计算rem*/
      win.addEventListener(
        "resize",
        function () {
          let width = docEl.getBoundingClientRect().width;
          if (width <= 1000) {
            refreshRem();
          }
        },
        false
      );

      win.addEventListener(
        "pageshow",
        function (e) {
          if (e.persisted) {
            // 浏览器后退的时候重新计算
            let width = docEl.getBoundingClientRect().width;
            if (width <= 1000) {
              refreshRem();
            }
          }
        },
        false
      );
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "hanSans";
  display: auto;
  src: url("./assets/font/SourceHanSansCN-Normal.otf");
}
@font-face {
  font-family: "hanRegular";
  display: auto;
  src: url("./assets/font/SourceHanSansCN-Regular.otf");
}
* {
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: #000000, $alpha: 0.4);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
:hover:-webkit-scrollbar-thumb {
  background-color: rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-track:hover {
  background-color: rgba($color: #000000, $alpha: 0.01);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}
body {
  font-size: 16px;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
.van-tabs__line {
  background-color: $theme;
}
.pangod-classify::after {
  content: "";
  display: table;
}
.loading {
  width: 100vw;
  height: 100vh;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-box {
  position: relative;
}
.pagination {
  text-align: center;
}
a {
  text-decoration: none;
}
.content,
.desc,
.pangod-classify-html {
  img,
  table,
  video {
    max-width: 100%;
    height: auto;
  }
  p {
    margin: 10px 0;
  }
}
@media (max-width: 1300px) {
  ::-webkit-scrollbar {
    width: 3px;
  }
}
@media (max-width: 750px) {
  ::-webkit-scrollbar {
    height: 0;
  }
}
</style>

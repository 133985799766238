<template>
  <div class="pangod-classify">
    <!-- 顶部 -->
    <BreadCrumb :routeArr="routeArr" v-if="!isMobile"></BreadCrumb>

    <!-- 二级导航栏 -->
    <TypeNavs
      :activeCode="activeCode"
      :tabNavList="tabNavList"
      @changeNav="changeNav"
    />
    <!-- 内容 -->
    <!-- 除招贤纳士和联系我们的其他一级导航栏 -->
    <div class="pangod-classify-content">
      <CustomLoading v-show="vanLoading" />
      <div class="organization-box">
        <div class="organization-title">盘古广告</div>
        <ul class="area">
          <li
            :class="['list', area_id == item.id ? 'area-on' : '']"
            v-for="item in areaList"
            :key="item.id"
            @click="changeArea(item.id)"
          >
            {{ item.title }}
          </li>
        </ul>
        <div class="company">
          <ul class="company-list">
            <li class="current" v-show="structNow.name">
              <div class="top">
                <div class="name">{{ structNow.name }}</div>
              </div>
              <div class="bottom">
                <div class="bg" v-if="structNow.companyUrl">
                  <iframe
                    :src="structNow.companyUrl"
                    frameborder="0"
                    width="1690px"
                    height="1000px"
                  ></iframe>
                </div>
                <div class="post" v-else>
                  <img :src="URL + structNow.imageUrl" :alt="structNow.name" />
                </div>
                <div class="content">
                  {{ structNow.description }}
                  <a
                    class="btn"
                    target="_blank"
                    :href="structNow.companyUrl"
                    v-show="structNow.companyUrl"
                  >
                    进入公司官网>>
                  </a>
                  <div class="btn" v-show="!structNow.companyUrl">
                    网站建设中
                  </div>
                </div>
              </div>
            </li>
            <li
              :class="{ active: strucIndex == idx }"
              v-for="(item, idx) in structureList"
              :key="item.id"
            >
              <div class="top" @click="changeAccordion(item.id, idx)">
                <div class="name">{{ item.name }}</div>
              </div>
            </li>
          </ul>
          <div class="empty" v-show="structureList.length == 0">暂无</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/breadCrumb.vue";
import CustomLoading from "@/components/loading.vue";
import TypeNavs from "../components/typeNavs.vue";

import { Store } from "../store";
import { URL } from "../util/config";
import { mapState } from "pinia";
export default {
  data() {
    return {
      URL,
      structureList: [],
      strucIndex: 100,
      structNow: {},
      areaList: [
        { id: 1, title: "成都盘古" },
        { id: 2, title: "重庆盘古" },
        { id: 3, title: "山西盘古" },
        { id: 5, title: "甘肃盘古" },
        { id: 6, title: "广西盘古" },
      ], // 地区数组
      area_id: 1,
      tabNavList: [],
      activeCode: "",
      params: {
        pageIndex: 1,
        pageSize: 12,
        newsCode: undefined,
        typeCode: undefined,
      },
      routeArr: [],
      vanLoading: false,
      detail: {},
    };
  },
  components: {
    BreadCrumb,
    CustomLoading,
    TypeNavs,
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
    // 富文本手机端兼容mobileContentStr字段
    handleHTML() {
      const { contentStr, mobileContentStr } = this.detail;
      if (this.isMobile) {
        return mobileContentStr || contentStr;
      } else {
        return contentStr;
      }
    },
  },
  mounted() {
    // 从其他路由到classify路由 或 首次进入网页为classify时
    this.setPageConfig();
  },
  methods: {
    // 构建页面参数
    setPageConfig() {
      const query = this.$route.params;
      // 父子id
      const { id, childId } = query;
      let nowDic = {};
      out: for (const i in this.navList) {
        const ele = this.navList[i];
        if (ele.id == id) {
          // 获取二级分类
          this.tabNavList = ele.children;
          if (childId) {
            // 两个参数
            for (const j in ele.children) {
              const child = ele.children[j];
              // 获取激活字典
              if (child.id == childId) {
                nowDic = child;
                this.getList(nowDic);
                break out;
              }
            }
          } else {
            // 一级字典
            nowDic = ele;
            this.getList(nowDic);
            break;
          }
        }
      }
      this.activeCode = nowDic.code;
      this.routeArr = [{ name: "首页", path: "/" }, nowDic.name];
    },
    // 获取内容
    getList(val) {
      this.params.newsCode = val.code;

      this.vanLoading = true;

      //获取列表
      this.$api
        .getCompany(this.area_id)
        .then((res) => {
          if (res.code === 0) {
            this.structureList = [...res.data];

            this.structNow = res.data.length > 1 ? {} : res.data[0];
          }
        })
        .finally(() => {
          this.vanLoading = false;
        });
    },
    // 切换菜单
    changeNav(val) {
      this.$router.push(
        val.templateName + "_" + this.$route.params.id + "/" + val.id
      );
    },
    // 根据 area_id 展示不同公司
    changeArea(id) {
      this.area_id = id;
      this.$api.getCompany(id).then((res) => {
        if (res.code === 0) {
          this.structureList = res.data;
          this.strucIndex = 0;
          this.structNow = res.data[0] || {};
        }
      });
    },
    // 改变手风琴作用对象
    changeAccordion(id, idx) {
      this.strucIndex = idx;

      this.structureList.forEach((ele) => {
        if (ele.id == id) {
          this.structNow = ele;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.pangod-classify {
  font-size: 16px;
  background-color: #eeeef0;

  --frame-width: 1500px;

  &::before {
    content: "";
    display: table;
  }

  .navBar {
    background: #eeeef0;

    .pangod-classify-nav {
      width: var(--frame-width);
      margin: 0 auto;
      background-color: #fff;
      line-height: 60px;

      .pangod-classify-nav-ul {
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: center;

        .pangod-classify-nav-li {
          padding: 0 20px;
          text-align: center;
          cursor: pointer;
        }
      }
      .active {
        color: rgba(2, 69, 140, 0.7);
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 50%;
          height: 3px;
          background: rgba(2, 69, 140, 0.7);
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          z-index: 2;
          opacity: 1;
        }
      }
    }
  }

  .pangod-classify-content {
    width: var(--frame-width);
    margin: 0 auto 20px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #ffffff;

    .van-loading {
      text-align: center;
    }
    .organization-box {
      font-family: "hanSans";
      color: #232a32;

      .organization-title {
        font-size: 35px;
        font-weight: bold;
        letter-spacing: 1px;
        margin: 30px auto 50px;
        width: 100%;
        text-align: center;
        position: relative;
        &::after {
          display: block;
          content: "";
          width: 90px;
          height: 3px;
          position: absolute;
          left: 50%;
          bottom: -15px;
          transform: translateX(-50%);
          background-color: #05376c;
        }
      }
      .area {
        font-family: "hanRegular";
        width: 1000px;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;

        .list {
          font-size: 20px;
          letter-spacing: 1px;
          padding: 10px 40px;

          &:hover {
            cursor: pointer;
            color: #91c81f;
          }
        }
        .area-on {
          color: #91c81f;
        }
      }
      .company {
        width: 1000px;
        margin: 50px auto 100px;
        overflow: hidden;

        .empty {
          text-align: center;
          margin-top: 5px;
        }

        .company-list {
          width: 99%;
          list-style: none;
          user-select: none;
          margin: 0 auto;
          border: 1px solid gray;
          color: gray;

          li {
            width: 100%;
            border-top: 1px solid gray;
            font-size: 15px;

            &:nth-child(2),
            &:first-child {
              border-top: 0;
            }

            .top {
              height: 36px;
              line-height: 36px;
              letter-spacing: 4px;
              padding-left: 20px;

              &:hover {
                cursor: pointer;
              }
            }
          }
          .current {
            .top {
              height: 61px;
              line-height: 61px;
              font-size: 20px;
              font-weight: 500;
              letter-spacing: 4px;
              color: #fff;
              text-align: center;
              background-color: #05376c;
            }

            .bottom {
              width: 100%;
              height: 300px;
              box-sizing: border-box;
              // padding: 2px;
              position: relative;
              overflow: hidden;
              transition: height 0.2s;

              .btn {
                font-weight: bold;
                font-size: 14px;
                color: #fff;
                position: absolute;
                padding: 2px 10px;
                border-radius: 4px;
                bottom: 10px;
                right: 20px;
                background-color: #91c81f;
              }

              .bg {
                transform: scale(0.58);
                transform-origin: 0 0;
              }
              .post {
                width: 100%;

                img {
                  width: 100%;
                }
              }

              .content {
                color: #fff;
                width: 100%;
                padding: 10px;
                box-sizing: border-box;
                position: absolute;
                top: 0;
                bottom: 0;
                background-color: rgba($color: #000, $alpha: 0.5);
              }
            }
          }

          .active {
            color: $theme;
            font-weight: 600;
          }
        }

        .only {
          width: 99%;

          .current {
            overflow: visible;

            .bg {
              transform: scale(0.55);
            }
            .content {
              width: 910px;
              // flex-grow: 1;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1600px) {
  .pangod-classify {
    --frame-width: 1200px;
  }
  .pangod-classify .pangod-classify-content {
    padding: pxToRem(10) pxToRem(20);
    box-sizing: border-box;
  }
  .pangod-classify-nav-li {
    font-size: 14px;
  }
}
@media (max-width: 1300px) {
  .pangod-classify {
    --frame-width: 1000px;

    .pangod-classify-content .organization-box {
      .company,
      .area {
        width: 900px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .pangod-classify {
    --frame-width: 100%;
  }
  .pangod-classify .pangod-classify-content {
    box-sizing: border-box;
    margin-bottom: pxToRem(10);
  }

  .pangod-classify .navBar .pangod-classify-nav {
    line-height: 30px;

    .pangod-classify-nav-ul {
      justify-content: start;
      white-space: nowrap;
      overflow-x: scroll;
      padding: pxToRem(5) pxToRem(10);
    }
  }
}
@media (max-width: 750px) {
  .pangod-classify .navBar .pangod-classify-nav {
    line-height: pxToRem(40);

    .pangod-classify-nav-ul {
      font-size: pxToRem(15);
    }
  }
  .pangod-classify .pangod-classify-content .organization-box {
    .organization-title {
      font-size: 24px;
      margin-bottom: 20px;

      &::after {
        width: 40px;
        bottom: -5px;
      }
    }
    .area {
      width: 100%;
      flex-wrap: wrap;

      .list {
        flex-shrink: 0;
        font-size: 16px;
        padding: 0 10px;
        margin-bottom: 10px;
      }
    }
    .company {
      width: 100%;
      margin: 30px auto 50px;

      .company-list {
        li {
          font-size: 15px;
          text-align: center;
        }

        .current {
          .top {
            font-size: 16px;
          }
          .bottom {
            .content {
              font-size: 13px;

              a {
                font-size: 12px;
              }
            }
            .bg {
              transform: scale(0.2, 0.3);
            }
          }
        }
      }

      .top {
        font-size: 14px;
        padding-left: 0 !important;
      }
    }
  }
}
</style>

<template>
  <div class="pangod-classify">
    <!-- 顶部 -->
    <BreadCrumb :routeArr="routeArr" v-if="!isMobile"></BreadCrumb>

    <!-- 二级导航栏 -->
    <TypeNavs
      :activeCode="activeCode"
      :tabNavList="tabNavList"
      @changeNav="changeNav"
    />
    <!-- 内容 -->
    <!-- 除招贤纳士和联系我们的其他一级导航栏 -->
    <div class="pangod-classify-content">
      <CustomLoading v-show="vanLoading" />
      <div class="timebox">
        <div class="time-top">
          <i
            class="el-icon-d-arrow-left arrow-icon"
            @click="changeLeft(1)"
            v-show="yearList.length > 10"
          ></i>
          <div id="yearlist" class="yearlist">
            <div
              class="pangod-classify-content-historyitem"
              :class="clickYear === item ? 'activecircle' : ''"
              v-for="(item, index) in yearList"
              :key="index"
              @click="changeYear(item)"
            >
              {{ item }}
            </div>
          </div>
          <i
            class="el-icon-d-arrow-right arrow-icon"
            @click="changeRight(1)"
            v-show="yearList.length > 10"
          ></i>
        </div>
        <el-timeline>
          <el-timeline-item v-for="his in historyList" :key="his.id">
            <p style="font-size: 16px" v-html="his.content"></p>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/breadCrumb.vue";
import CustomLoading from "@/components/loading.vue";
import TypeNavs from "../components/typeNavs.vue";

import { Store } from "../store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      tabNavList: [],
      activeCode: "",
      params: {
        pageIndex: 1,
        pageSize: 12,
        newsCode: undefined,
        typeCode: undefined,
      },
      routeArr: [],
      vanLoading: false,
      detail: {},
      yearList: [], // 年限列表
      clickYear: null, // 当前年限
      historyList: [], // 年限发生事件列表
      domObj: null,
    };
  },
  components: {
    BreadCrumb,
    CustomLoading,
    TypeNavs,
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
    // 富文本手机端兼容mobileContentStr字段
    handleHTML() {
      const { contentStr, mobileContentStr } = this.detail;
      if (this.isMobile) {
        return mobileContentStr || contentStr;
      } else {
        return contentStr;
      }
    },
  },
  mounted() {
    // 从其他路由到classify路由 或 首次进入网页为classify时
    this.setPageConfig();
  },
  methods: {
    // 构建页面参数
    setPageConfig() {
      const query = this.$route.params;
      // 父子id
      const { id, childId } = query;
      let nowDic = {};
      out: for (const i in this.navList) {
        const ele = this.navList[i];
        if (ele.id == id) {
          // 获取二级分类
          this.tabNavList = ele.children;
          if (childId) {
            // 两个参数
            for (const j in ele.children) {
              const child = ele.children[j];
              // 获取激活字典
              if (child.id == childId) {
                nowDic = child;
                this.getList(nowDic);
                break out;
              }
            }
          } else {
            // 一级字典
            nowDic = ele;
            this.getList(nowDic);
            break;
          }
        }
      }
      this.activeCode = nowDic.code;
      this.routeArr = [{ name: "首页", path: "/" }, nowDic.name];
    },
    // 获取内容
    async getList(val) {
      this.params.newsCode = val.code;
      // 获取年限
      let res = await this.$api.sysHistoryyears();
      if (res.code === 0) {
        this.yearList = res.data;
        this.clickYear = this.yearList[0];
        this.scrollFunction();
        this.getYearList(this.yearList[0]);
      }
    },
    changeLeft(count) {
      let { yearList, clickYear } = this;

      if (clickYear !== yearList[0]) {
        for (var i in yearList) {
          if (clickYear === yearList[i]) {
            this.clickYear = yearList[i - count];
            if (this.isMobile) {
              this.domObj.scrollTop = this.domObj.scrollTop - 90;
            } else {
              this.domObj.scrollLeft = this.domObj.scrollLeft - 120;
            }
            this.getYearList(this.clickYear);
            return;
          }
        }
      }
    },
    changeRight(count) {
      let { yearList, clickYear } = this;

      if (clickYear !== yearList[yearList.length - 1]) {
        for (var i = 0; i < yearList.length; i++) {
          if (clickYear === yearList[i]) {
            this.clickYear = yearList[i + count];
            if (this.isMobile) {
              this.domObj.scrollTop = this.domObj.scrollTop + 90;
            } else {
              this.domObj.scrollLeft = this.domObj.scrollLeft + 120;
            }
            this.getYearList(this.clickYear);
            return;
          }
        }
      }
    },
    changeYear(val) {
      const value = Math.abs(this.clickYear - val);

      if (val < this.clickYear) {
        this.changeRight(value);
      } else if (val > this.clickYear) {
        this.changeLeft(value);
      }
    },
    getYearList(val) {
      //获取年限发生事件
      this.$api
        .syslistHistory({
          year: val,
        })
        .then((res) => {
          if (res.code === 0) {
            this.historyList = res.data;
          }
        });
    },
    scrollFunction() {
      this.domObj = document.getElementById("yearlist"); // 通过id获取要设置的div
      if (this.domObj.attachEvent) {
        // IE
        this.domObj.attachEvent("onmousewheel", this.mouseScroll);
      } else if (this.domObj.addEventListener) {
        this.domObj.addEventListener("DOMMouseScroll", this.mouseScroll, false);
      }
      this.domObj.onmousewheel = this.domObj.onmousewheel = this.mouseScroll;
    },
    // 切换菜单
    changeNav(val) {
      this.$router.push(
        val.templateName + "_" + this.$route.params.id + "/" + val.id
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.pangod-classify {
  font-size: 16px;
  background-color: #eeeef0;

  --frame-width: 1500px;

  &::before {
    content: "";
    display: table;
  }

  .navBar {
    background: #eeeef0;

    .pangod-classify-nav {
      width: var(--frame-width);
      margin: 0 auto;
      background-color: #fff;
      line-height: 60px;

      .pangod-classify-nav-ul {
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: center;

        .pangod-classify-nav-li {
          padding: 0 20px;
          text-align: center;
          cursor: pointer;
        }
      }
      .active {
        color: rgba(2, 69, 140, 0.7);
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 50%;
          height: 3px;
          background: rgba(2, 69, 140, 0.7);
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          z-index: 2;
          opacity: 1;
        }
      }
    }
  }

  .pangod-classify-content {
    width: var(--frame-width);
    margin: 0 auto 20px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #ffffff;

    .van-loading {
      text-align: center;
    }
  }
  .timebox {
    width: fit-content;
    margin: 0 auto;
    .arrow-icon {
      font-size: 40px;
      color: #91c81f;
      margin: 30px 10px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      font-weight: bold;
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      -khtml-user-select: none; /*早期浏览器*/
      user-select: none;
    }
    .pangod-classify-content-historyitem {
      display: inline-block;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      border-radius: 50%;
      background: rgba(150, 150, 150, 0.5);
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      cursor: pointer;
      margin: 10px 40px 10px 0;
      position: relative;
      transition: all 0.3s;
      &:last-child {
        margin-right: 0;
        &::after {
          content: "";
        }
      }
      &::after {
        content: "...";
        color: #91c81f;
        position: absolute;
        left: 110%;
      }
      &:hover {
        transform: scale(1.1);
        background-color: #91c81f;
      }
    }
    .activecircle {
      transform: scale(1.1);
      background-color: #91c81f;
    }
    .yearlist {
      overflow-x: scroll;
      white-space: nowrap;
      margin-bottom: 40px;
      width: 916px;
      padding: 0 20px;
      display: inline-block;
      scrollbar-width: none;
      -ms-overflow-style: none; /* IE 10+ */
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      -khtml-user-select: none; /*早期浏览器*/
      user-select: none;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
  }
}
@media (max-width: 1600px) {
  .pangod-classify {
    --frame-width: 1200px;

    .timebox .yearlist {
      width: 796px;
    }
  }
  .pangod-classify .pangod-classify-content {
    padding: pxToRem(10) pxToRem(20);
    box-sizing: border-box;
  }
  .pangod-classify-nav-li {
    font-size: 14px;
  }
}
@media (max-width: 1300px) {
  .pangod-classify {
    --frame-width: 1000px;

    .timebox {
      width: 850px;

      .yearlist {
        width: 680px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .pangod-classify {
    --frame-width: 100%;
  }
  .pangod-classify .pangod-classify-content {
    box-sizing: border-box;
    margin-bottom: pxToRem(1);
  }

  .pangod-classify .navBar .pangod-classify-nav {
    line-height: 30px;

    .pangod-classify-nav-ul {
      justify-content: start;
      white-space: nowrap;
      overflow-x: scroll;
      padding: pxToRem(5) pxToRem(10);
    }
  }
}
@media (max-width: 750px) {
  .pangod-classify .navBar .pangod-classify-nav {
    line-height: pxToRem(40);

    .pangod-classify-nav-ul {
      font-size: pxToRem(15);
    }
  }
  .pangod-classify-content-historyitem::after {
    display: none;
  }
  .pangod-classify {
    .pangod-classify-content {
      padding: 0.2rem;
      overflow: hidden;
    }
    .timebox {
      width: 100%;
      height: 70vh;
      display: flex;
      margin-left: 0;

      .time-top {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .arrow-icon {
        display: block;
        margin: 0;

        &:first-child,
        &:last-child {
          transform: rotate(90deg);
        }
      }

      #yearlist {
        width: auto;
        margin-bottom: 0;
        padding: 0 10px 0 5px;
      }

      .pangod-classify-content-historyitem {
        display: block;
        margin-right: 0;

        &::after {
          display: none;
        }
      }

      .el-timeline {
        width: 100%;
        font-size: pxToRem(14);
        margin-left: pxToRem(10);
        overflow: auto;

        .el-timeline-item {
          padding-bottom: pxToRem(20);

          .el-timeline-item__tail {
            left: pxToRem(7);
          }
          .el-timeline-item__node--normal {
            width: pxToRem(16);
            height: pxToRem(16);
          }
          .el-timeline-item__wrapper {
            padding-left: pxToRem(25);
          }
          .el-timeline-item__content p {
            font-size: 12px !important;
          }
          .el-timeline-item__timestamp {
            font-size: pxToRem(11);
          }
        }
      }
    }
  }
}
</style>

<template>
  <header class="pangod-top">
    <!-- pc端 -->
    <div
      v-if="!isMobile"
      :class="isPlaceHolder ? 'placeholder' : ''"
      @mouseover="changeLogo(true)"
      @mouseleave="changeLogo(false)"
    >
      <div :class="['topNav', isShowbgcolor ? 'navbgcolor' : 'pangod-nav']">
        <ul class="pangod-top-ul">
          <div class="big-logo">
            <el-image
              v-show="isShowbgcolor"
              class="logo-img"
              fit="contain"
              :src="require('@/assets/logo.png')"
              @click="homePush"
            ></el-image>
            <el-image
              v-show="!isShowbgcolor"
              class="logo-img"
              fit="contain"
              :src="require('@/assets/logo-white.png')"
              @click="homePush"
            ></el-image>
          </div>
          <!-- 屏宽1300以下的logo图 -->
          <div class="little-logo">
            <el-image
              v-show="isShowbgcolor"
              class="little-logo-img"
              fit="contain"
              :src="require('@/assets/little-logo.png')"
              @click="homePush"
            ></el-image>
            <el-image
              v-show="!isShowbgcolor"
              class="little-logo-img"
              fit="contain"
              :src="require('@/assets/little-logo-white.png')"
              @click="homePush"
            ></el-image>
          </div>

          <li
            :class="{
              'pangod-top-ul-li': true,
              'active-nav': $route.params.id == item.id,
            }"
            v-for="item in navList"
            :key="item.id"
            @click="changeNav(item, null)"
          >
            <a class="pangod-top-ul-li-span">{{ item.name }}</a>
            <ul class="pangod-top-ul-item">
              <li
                :class="{ sroll: child.name.length > 7 }"
                v-for="child in item.children"
                :key="child.id"
                @click.stop="changeNav(child, item)"
              >
                <div>{{ child.name }}</div>
              </li>
            </ul>
          </li>
          <!-- 咨询电话 -->
          <li class="pangod-top-ul-li">
            <span
              >咨询电话:<img
                src="@/assets/phone.png"
                alt=""
              />400-6222-028</span
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- 手机端 -->
    <div class="m-pangod-top" v-else>
      <van-nav-bar title="盘古广告" fixed placeholder z-index="99">
        <template #left>
          <van-icon
            name="wap-home-o"
            size="22"
            @click="$route.path !== '/' && $router.push('/')"
          />
        </template>
        <template #right>
          <van-icon name="bars" size="22" @click="navShow = true" />
        </template>
      </van-nav-bar>
      <van-popup
        v-model="navShow"
        position="right"
        :style="{ width: '30%', height: '100%' }"
      >
        <ul>
          <li
            :class="{
              'active-nav': $route.params.id == item.id,
            }"
            v-for="item in navList"
            :key="item.id"
            @click="changeNav(item, null)"
          >
            {{ item.name }}
          </li>
          <li @click="subShow = !subShow">
            <i
              :class="[
                'left',
                subShow ? 'el-icon-arrow-down' : 'el-icon-arrow-left',
              ]"
            ></i
            >集团及旗下公司
          </li>
          <div :class="['subs', subShow ? 'show' : '']">
            <div
              class="navSub"
              v-for="item in friendLink"
              :key="item.id"
              @click="openLink(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </ul>
      </van-popup>
    </div>
  </header>
</template>

<script>
import { Store } from "@/store/index.js";
import { mapState } from "pinia";
export default {
  data() {
    return {
      show: false,
      searchData: "",
      path: "",
      businessCode: "", // 业务介绍 code 用于特殊处理
      navShow: false,
      subShow: false,
    };
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList", "friendLink"]),
  },
  props: {
    isShowbgcolor: {
      type: Boolean,
      default: true,
    },
    // 悬浮时是否生成一个等高占位符
    isPlaceHolder: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  methods: {
    changeNav(val, parent) {
      // 提取参数
      const { id, children } = val;
      window.scrollTo(0, 0);
      if (id == this.navList[0].id) {
        // 首页
        this.$router.push("/");
      } else {
        if (parent) {
          // 点击二级字典 直接跳转
          this.$router.push(val.templateName + "_" + parent.id + "/" + val.id);
        } else {
          // 点击一级字典
          const defaultRoute = children[0];
          if (defaultRoute) {
            // 有子级，默认第一个
            this.$router.push(
              defaultRoute.templateName + "_" + val.id + "/" + defaultRoute.id
            );
          } else {
            // 无子级，直接跳转
            this.$router.push(val.templateName + "_" + val.id);
          }
        }
      }

      this.navShow = false;
    },
    changeLogo(bol) {
      if (document.documentElement.scrollTop > 72) return;
      this.$emit("mouseevent", bol);
    },
    homePush() {
      this.$router.push("/");
    },
    openLink(row) {
      window.open(row.hrefUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.van-nav-bar {
  position: fixed;
  width: 100%;
}
.van-icon {
  font-size: pxToRem(22) !important;
}
.van-nav-bar__title {
  font-size: pxToRem(16);
}
.van-nav-bar__content {
  height: pxToRem(46);
}
.van-nav-bar {
  line-height: normal;
  z-index: 888;
}

.little-logo {
  display: none;
}
.placeholder::after {
  content: "";
  display: block;
  width: 100%;
  height: 95px;
}
.navbgcolor {
  color: $default;
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;
  box-shadow: 0 15px 16px rgba(0, 0, 0, 0.02);
}
.pangod-nav {
  color: #fff;
}

.topNav {
  width: 100%;
  height: 95px;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 999;
  transition: all 0.3s;

  &:hover {
    background-color: #fff;
    color: $default;
  }
  .pangod-top-ul {
    width: 1500px;
    height: 95px;
    margin: 0 auto;
    list-style: none;
    line-height: 32px;
    font-weight: bold;
    font-size: 16px;
    position: relative;

    .logo-img {
      width: 400px;
      float: left;
      top: 20px;

      &:hover {
        cursor: pointer;
      }
    }
    .little-logo-img {
      width: 140px;
      float: left;
      top: 20px;

      &:hover {
        cursor: pointer;
      }
    }
    .pangod-top-ul-li {
      width: calc((100% - 400px) / 9);
      text-align: center;
      float: left;
      cursor: pointer;
      background: none;
      text-transform: uppercase;
      position: relative;
      outline: none;
      padding: 31px 0;
      box-sizing: border-box;
      position: relative;
      z-index: 11;

      &:last-child {
        width: 224px;
        user-select: text;
        white-space: nowrap;
        transform: scale(1.2);
        transform-origin: left;
        text-align: left;

        img {
          width: 30px;
          vertical-align: middle;
        }
      }
      &:hover {
        .pangod-top-ul-item {
          max-height: 500px;
          margin-top: 10px;
          transition: all 0.3s linear;

          li {
            padding: 5px 0;
          }
        }
        .pangod-top-ul-li-span::before {
          width: 50px;
          height: 30px;
          left: 50%;
          transform: translateX(-50%);
          border-bottom-color: $theme;
          transition: width 0.2s ease-out 0.2s;
        }
      }
      .pangod-top-ul-item {
        width: 100%;
        max-height: 0px;
        font-size: 14px;
        color: #333;
        background-color: #ffffff;
        position: absolute;
        top: 85px;
        overflow: hidden;
        transition: all 0.1s linear;
        box-shadow: 1px 3px 5px rgb(216, 216, 216);
      }
      li {
        white-space: nowrap;
        transition: all 0.2s;
        &:hover {
          color: $theme;
          background-color: rgb(243, 243, 243);
        }
      }
      .pangod-top-ul-li-span {
        &::before {
          width: 0px;
          height: 0px;
          content: "";
          box-sizing: inherit;
          position: absolute;
          border-bottom: 3px solid transparent;
          border-radius: 2px;
        }
      }
    }

    .active-nav .pangod-top-ul-li-span::before {
      width: 50px;
      height: 30px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom-color: #3c79f3;
      transition: width 0.2s ease-out 0.2s;
    }
  }
}
@media (max-width: 1600px) {
  .topNav .pangod-top-ul {
    width: 1200px;

    .pangod-top-ul-li {
      width: 90px;

      &:last-child {
        font-size: 14px;
      }
      .pangod-top-ul-li-span {
        font-size: 14px;
      }
      .pangod-top-ul-item {
        font-size: 12px;

        .sroll {
          height: 32px;
          padding: 5px;
          position: relative;

          div {
            width: fit-content;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0.8);
          }
        }
      }
    }
    .logo-img {
      width: 320px;
    }
  }
}
@media (max-width: 1300px) {
  .topNav .pangod-top-ul {
    width: 1000px;
  }
  .little-logo {
    display: block;
  }
  .big-logo {
    display: none;
  }
}
.m-pangod-top {
  width: 100%;

  ul {
    list-style: none;
    font-size: pxToRem(14);
    line-height: pxToRem(50);
    color: #000;
    background-color: #fff;

    li {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      cursor: pointer;
      position: relative;
    }
    .active-nav {
      color: $theme;
    }
    .left {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
    .subs {
      display: none;
      transition: all 0.5s;
    }
    .show {
      display: block;
    }
    .navSub {
      text-align: center;
      color: rgb(102, 102, 102);
      font-size: 13px;
      background-color: rgba(248, 248, 248, 0.6);
    }
  }
}
</style>

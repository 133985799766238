var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"pangod-top"},[(!_vm.isMobile)?_c('div',{class:_vm.isPlaceHolder ? 'placeholder' : '',on:{"mouseover":function($event){return _vm.changeLogo(true)},"mouseleave":function($event){return _vm.changeLogo(false)}}},[_c('div',{class:['topNav', _vm.isShowbgcolor ? 'navbgcolor' : 'pangod-nav']},[_c('ul',{staticClass:"pangod-top-ul"},[_c('div',{staticClass:"big-logo"},[_c('el-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowbgcolor),expression:"isShowbgcolor"}],staticClass:"logo-img",attrs:{"fit":"contain","src":require('@/assets/logo.png')},on:{"click":_vm.homePush}}),_c('el-image',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShowbgcolor),expression:"!isShowbgcolor"}],staticClass:"logo-img",attrs:{"fit":"contain","src":require('@/assets/logo-white.png')},on:{"click":_vm.homePush}})],1),_c('div',{staticClass:"little-logo"},[_c('el-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowbgcolor),expression:"isShowbgcolor"}],staticClass:"little-logo-img",attrs:{"fit":"contain","src":require('@/assets/little-logo.png')},on:{"click":_vm.homePush}}),_c('el-image',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShowbgcolor),expression:"!isShowbgcolor"}],staticClass:"little-logo-img",attrs:{"fit":"contain","src":require('@/assets/little-logo-white.png')},on:{"click":_vm.homePush}})],1),_vm._l((_vm.navList),function(item){return _c('li',{key:item.id,class:{
            'pangod-top-ul-li': true,
            'active-nav': _vm.$route.params.id == item.id,
          },on:{"click":function($event){return _vm.changeNav(item, null)}}},[_c('a',{staticClass:"pangod-top-ul-li-span"},[_vm._v(_vm._s(item.name))]),_c('ul',{staticClass:"pangod-top-ul-item"},_vm._l((item.children),function(child){return _c('li',{key:child.id,class:{ sroll: child.name.length > 7 },on:{"click":function($event){$event.stopPropagation();return _vm.changeNav(child, item)}}},[_c('div',[_vm._v(_vm._s(child.name))])])}),0)])}),_vm._m(0)],2)])]):_c('div',{staticClass:"m-pangod-top"},[_c('van-nav-bar',{attrs:{"title":"盘古广告","fixed":"","placeholder":"","z-index":"99"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"wap-home-o","size":"22"},on:{"click":function($event){_vm.$route.path !== '/' && _vm.$router.push('/')}}})]},proxy:true},{key:"right",fn:function(){return [_c('van-icon',{attrs:{"name":"bars","size":"22"},on:{"click":function($event){_vm.navShow = true}}})]},proxy:true}])}),_c('van-popup',{style:({ width: '30%', height: '100%' }),attrs:{"position":"right"},model:{value:(_vm.navShow),callback:function ($$v) {_vm.navShow=$$v},expression:"navShow"}},[_c('ul',[_vm._l((_vm.navList),function(item){return _c('li',{key:item.id,class:{
            'active-nav': _vm.$route.params.id == item.id,
          },on:{"click":function($event){return _vm.changeNav(item, null)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('li',{on:{"click":function($event){_vm.subShow = !_vm.subShow}}},[_c('i',{class:[
              'left',
              _vm.subShow ? 'el-icon-arrow-down' : 'el-icon-arrow-left',
            ]}),_vm._v("集团及旗下公司 ")]),_c('div',{class:['subs', _vm.subShow ? 'show' : '']},_vm._l((_vm.friendLink),function(item){return _c('div',{key:item.id,staticClass:"navSub",on:{"click":function($event){return _vm.openLink(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)],2)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"pangod-top-ul-li"},[_c('span',[_vm._v("咨询电话:"),_c('img',{attrs:{"src":require("@/assets/phone.png"),"alt":""}}),_vm._v("400-6222-028")])])
}]

export { render, staticRenderFns }
<template>
  <div class="pangod-classify">
    <!-- 顶部 -->
    <BreadCrumb :routeArr="routeArr" v-if="!isMobile"></BreadCrumb>

    <!-- 二级导航栏 -->
    <TypeNavs
      :activeCode="activeCode"
      :tabNavList="tabNavList"
      @changeNav="changeNav"
    />
    <!-- 内容 -->
    <!-- 除招贤纳士和联系我们的其他一级导航栏 -->
    <div class="pangod-classify-content">
      <CustomLoading v-show="vanLoading" />
      <div class="contact-box" v-if="!isMobile">
        <div
          class="pangod-classify-content-address"
          v-for="(item, index) in addressList"
          :key="item.id"
        >
          <h3>
            <img
              style="margin-right: 10px; vertical-align: top"
              src="../assets/contactIcon3.png"
            />{{ item.companyName }}
          </h3>
          <div
            class="pangod-classify-content-address-item"
            v-html="item.companyAddress"
          ></div>
          <div class="pangod-classify-content-address-item">
            电话：{{ item.companyPhone }}
            <span style="margin-left: 10px" v-show="index === 0"
              >邮编：610000</span
            >
          </div>
          <div v-show="index === 0">
            <div class="pangod-classify-content-address-item">
              人事与行政：hr@pangod.com
            </div>
            <div class="pangod-classify-content-address-item">
              合作与建议：contact@pangod.com
            </div>
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <van-tabs v-model="activeName" color="#409EFF" v-else>
        <van-tab title="公司地址" name="address">
          <div class="contact-box">
            <div
              class="contact"
              v-for="(item, idx) in addressList"
              :key="item.id"
            >
              <div class="left">
                <img class="icon" src="@/assets/contactIcon3.png" />
                <span>{{ item.companyName }}</span>
              </div>
              <div class="right">
                <div class="address-item" v-html="item.companyAddress"></div>
                <div class="address-item">
                  <span>电话：{{ item.companyPhone }}</span>
                  <span style="margin-left: 10px" v-if="idx === 0"
                    >邮编：610000</span
                  >
                </div>
                <div class="other" v-if="idx === 0">
                  <div class="address-item">人事与行政：hr@pangod.com</div>
                  <div class="address-item">合作与建议：contact@pangod.com</div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="留言咨询" name="consult">
          <van-form ref="form" validate-first>
            <!-- 通过 pattern 进行正则校验 -->
            <van-field
              v-model="addParams.userName"
              name="pattern"
              placeholder="请输入姓名"
              :rules="[{ required: true, message: '姓名不能为空!' }]"
            />
            <!-- 通过 validator 进行函数校验 -->
            <van-field
              v-model="addParams.phone"
              type="tel"
              placeholder="请输入手机号"
              :rules="[
                { pattern: phoneRule, message: '请输入正确格式的手机号' },
              ]"
            />
            <van-field
              v-model="addParams.address"
              placeholder="请输入所在区域"
            />
            <van-field
              v-model="addParams.company"
              placeholder="请输入公司名称"
              :rules="[{ required: true, message: '公司名称不能为空' }]"
            />
            <van-field
              v-model="addParams.content"
              type="textarea"
              rows="2"
              autosize
              placeholder="请输入留言内容"
              :rules="[{ required: true, message: '留言内容不能为空!' }]"
            />
            <div class="btn-box">
              <el-button size="small" type="primary" @click="onSubmit"
                >提交</el-button
              >
            </div>
          </van-form>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/breadCrumb.vue";
import CustomLoading from "@/components/loading.vue";
import TypeNavs from "../components/typeNavs.vue";

import { Store } from "../store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      tabNavList: [],
      activeCode: "",
      params: {
        pageIndex: 1,
        pageSize: 12,
        newsCode: undefined,
        typeCode: undefined,
      },
      routeArr: [],
      vanLoading: false,
      detail: {},
      addressList: [],
      phoneRule: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
      addParams: {
        userName: "",
        phone: "",
        address: "",
        company: "",
        content: "",
      },
      activeName: "",
    };
  },
  components: {
    BreadCrumb,
    CustomLoading,
    TypeNavs,
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
    // 富文本手机端兼容mobileContentStr字段
    handleHTML() {
      const { contentStr, mobileContentStr } = this.detail;
      if (this.isMobile) {
        return mobileContentStr || contentStr;
      } else {
        return contentStr;
      }
    },
  },
  mounted() {
    // 从其他路由到classify路由 或 首次进入网页为classify时
    this.setPageConfig();
    if (this.$route.query.type == "consult") {
      // 带参数直接跳转留言咨询
      this.activeName = this.$route.query.type;
    }
  },
  methods: {
    // 构建页面参数
    setPageConfig() {
      const query = this.$route.params;
      // 父子id
      const { id, childId } = query;
      let nowDic = {};
      out: for (const i in this.navList) {
        const ele = this.navList[i];
        if (ele.id == id) {
          // 获取二级分类
          this.tabNavList = ele.children;
          if (childId) {
            // 两个参数
            for (const j in ele.children) {
              const child = ele.children[j];
              // 获取激活字典
              if (child.id == childId) {
                nowDic = child;
                this.getList(nowDic);
                break out;
              }
            }
          } else {
            // 一级字典
            nowDic = ele;
            this.getList(nowDic);
            break;
          }
        }
      }
      this.activeCode = nowDic.code;
      this.routeArr = [{ name: "首页", path: "/" }, nowDic.name];
    },
    // 获取内容
    getList(val) {
      this.params.newsCode = val.code;
      this.vanLoading = true;

      //获取列表
      this.$api
        .syscompanysList()
        .then((res) => {
          if (res.code === 0) {
            this.addressList = res.data;
          }
        })
        .finally(() => {
          this.vanLoading = false;
        });
    },
    // 切换菜单
    changeNav(val) {
      this.$router.push(
        val.templateName + "_" + this.$route.params.id + "/" + val.id
      );
    },
    // 验证提交
    onSubmit() {
      this.$refs.form
        .validate()
        .then(async () => {
          let res = await this.$api.addFeed(this.params);
          if (res.code == 0) {
            this.$toast.success("提交成功！我们会尽快与您联系！");
          }
        })
        .catch(() => {
          console.log("验证失败");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.pangod-classify {
  font-size: 16px;
  background-color: #eeeef0;

  --frame-width: 1500px;

  &::before {
    content: "";
    display: table;
  }

  .navBar {
    background: #eeeef0;

    .pangod-classify-nav {
      width: var(--frame-width);
      margin: 0 auto;
      background-color: #fff;
      line-height: 60px;

      .pangod-classify-nav-ul {
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: center;

        .pangod-classify-nav-li {
          padding: 0 20px;
          text-align: center;
          cursor: pointer;
        }
      }
      .active {
        color: rgba(2, 69, 140, 0.7);
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 50%;
          height: 3px;
          background: rgba(2, 69, 140, 0.7);
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          z-index: 2;
          opacity: 1;
        }
      }
    }
  }

  .pangod-classify-content {
    width: var(--frame-width);
    margin: 0 auto 20px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #ffffff;

    .van-loading {
      text-align: center;
    }

    .contact-box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;

      .pangod-classify-content-address {
        padding: 20px 10px;
        width: calc(50% - 20px);
        border-bottom: 1px solid #ddd;

        .pangod-classify-content-address-item {
          color: #666;
          margin: 10px 0;
        }
        &:nth-child(1) {
          width: 100%;
        }
      }

      .contact {
        width: 50%;
        padding: 50px 20px 50px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        &:first-child {
          width: 60%;
          margin: 0 auto;
          flex-direction: column;

          .left {
            border-right: 0;
            margin-bottom: 30px;
            margin-right: 0;

            &::after {
              display: block;
            }
          }
        }
        & + .contact {
          border-top: 1px solid #eee;
        }

        .left {
          width: 200px;
          flex-shrink: 0;
          font-size: 20px;
          font-weight: bold;
          border-right: 1px solid #e6e6e6;
          text-align: center;
          margin-right: 20px;
          position: relative;

          &::after {
            content: "";
            width: 40%;
            height: 1px;
            background-color: #e6e6e6;
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%);
            display: none;
          }

          .icon {
            margin-right: 10px;
            vertical-align: middle;
          }
        }
        .right {
          font-size: 14px;

          .address-item {
            color: #666;
            margin: 5px 0;
          }
        }
      }
    }
    .feed-title {
      color: #666;
      text-align: center;
      font-size: 0.44rem;
      margin-top: 0.5rem;
    }
    .feed-line {
      width: 0.8rem;
      height: 2px;
      margin: 0.2rem auto;
      background-color: #3c79f3;
    }
    .btn-box {
      width: 50vw;
      margin: 16px auto;

      .el-button {
        width: 100%;
      }
    }
  }
}
.van-form {
  margin-top: 30px;
}
@media (max-width: 1600px) {
  .pangod-classify {
    --frame-width: 1200px;
  }
  .pangod-classify .pangod-classify-content {
    padding: pxToRem(10) pxToRem(20);
    box-sizing: border-box;

    .contact-box {
      width: 1000px;

      .contact .left {
        width: 160px;
        font-size: 16px;
      }
    }
  }
  .pangod-classify-nav-li {
    font-size: 14px;
  }
}
@media (max-width: 1300px) {
  .pangod-classify {
    --frame-width: 1000px;

    .pangod-classify-content .contact-box {
      width: 900px;

      .contact {
        padding: 20px 20px 20px 0;

        .left {
          width: 150px;
          font-size: 15px;
          margin-right: 10px;
        }
        .right {
          font-size: 13px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .pangod-classify {
    --frame-width: 100%;
  }
  .pangod-classify .pangod-classify-content {
    box-sizing: border-box;
    margin-bottom: pxToRem(10);
  }

  .pangod-classify .navBar .pangod-classify-nav {
    line-height: 30px;

    .pangod-classify-nav-ul {
      justify-content: start;
      white-space: nowrap;
      overflow-x: scroll;
      padding: pxToRem(5) pxToRem(10);
    }
  }
  .contact-box {
    .pangod-classify-content-address {
      width: 100%;
      font-size: pxToRem(14);

      img {
        width: pxToRem(20);
      }
    }
  }
}
@media (max-width: 750px) {
  .pangod-classify .navBar .pangod-classify-nav {
    line-height: pxToRem(40);

    .pangod-classify-nav-ul {
      font-size: pxToRem(15);
    }
  }
  .pangod-classify .pangod-classify-content .contact-box {
    width: 100%;

    .contact {
      width: 100%;
      padding: 20px 0;
      flex-direction: column;
      align-items: flex-start;

      &:first-child {
        width: 100%;
      }

      .left {
        width: 100%;
        text-align: left;
        font-size: 18px;
        margin-right: 10px;
        margin-bottom: 10px !important;
        border-right: 0;

        &::after {
          display: none !important;
        }
      }
      .right {
        font-size: 14px;
      }
    }
  }
}
</style>

<template>
  <div class="pangod-classify">
    <!-- 顶部 -->
    <BreadCrumb :routeArr="routeArr" v-if="!isMobile"></BreadCrumb>

    <!-- 二级导航栏 -->
    <TypeNavs
      :activeCode="activeCode"
      :tabNavList="tabNavList"
      @changeNav="changeNav"
    />
    <!-- 内容 -->
    <!-- 除招贤纳士和联系我们的其他一级导航栏 -->
    <div class="pangod-classify-content">
      <CustomLoading v-show="vanLoading" />
      <h2 class="h2-title">现有合作</h2>
      <div class="certifications">
        <div class="certification" v-for="item in nowList" :key="item.id">
          <div class="image">
            <img :src="URL + item.imgUrl" @click="preview(item.imgUrl)" />
          </div>
          <div class="desc">
            <p class="title">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <h2 class="h2-title">过往合作</h2>
      <div class="certifications">
        <div class="certification" v-for="item in hisList" :key="item.id">
          <div class="image">
            <img :src="URL + item.imgUrl" @click="preview(item.imgUrl)" />
          </div>
          <div class="desc">
            <p class="title">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div class="up" v-show="nowImage" @click="close">
        <img class="close" src="@/assets/classify/close.png" alt="" />
        <img class="show" :src="nowImage" alt="" @click.stop />
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/breadCrumb.vue";
import CustomLoading from "@/components/loading.vue";
import TypeNavs from "../components/typeNavs.vue";

import { URL } from "@/util/config.js";
import { Store } from "../store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      URL,
      hisList: [],
      nowList: [],
      tabNavList: [],
      activeCode: "",
      params: {
        pageIndex: 1,
        pageSize: 12,
        newsCode: undefined,
        typeCode: undefined,
      },
      routeArr: [],
      vanLoading: false,
      detail: {},
      nowImage: "", // 放大图
    };
  },
  components: {
    BreadCrumb,
    CustomLoading,
    TypeNavs,
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
  },
  mounted() {
    // 从其他路由到classify路由 或 首次进入网页为classify时
    this.setPageConfig();
  },
  methods: {
    // 构建页面参数
    setPageConfig() {
      const query = this.$route.params;
      // 父子id
      const { id, childId } = query;
      let nowDic = {};
      out: for (const i in this.navList) {
        const ele = this.navList[i];
        if (ele.id == id) {
          // 获取二级分类
          this.tabNavList = ele.children;
          if (childId) {
            // 两个参数
            for (const j in ele.children) {
              const child = ele.children[j];
              // 获取激活字典
              if (child.id == childId) {
                nowDic = child;
                this.getNowList(nowDic);
                break out;
              }
            }
          } else {
            // 一级字典
            nowDic = ele;
            this.getNowList(nowDic);
            break;
          }
        }
      }
      this.activeCode = nowDic.code;
      this.routeArr = [{ name: "首页", path: "/" }, nowDic.name];
    },
    // 获取现有资质
    getNowList(val) {
      this.params.newsCode = val.code;
      this.vanLoading = true;

      const params = {
        honorType: 1,
        historyFlag: 0,
      };
      //获取列表
      this.$api.sysHonorList(params).then((res) => {
        if (res.code === 0) {
          this.nowList = res.data;
        }
      });
      this.getLastestList();
    },
    // 获取过往资质
    getLastestList() {
      const params = {
        honorType: 1,
        historyFlag: 1,
      };
      //获取列表
      this.$api
        .sysHonorList(params)
        .then((res) => {
          if (res.code === 0) {
            this.hisList = res.data;
          }
        })
        .finally(() => {
          this.vanLoading = false;
        });
    },
    // 切换菜单
    changeNav(val) {
      this.$router.push(
        val.templateName + "_" + this.$route.params.id + "/" + val.id
      );
    },
    preview(url) {
      this.nowImage = this.URL + url;
    },
    close() {
      this.nowImage = "";
    },
  },
};
</script>
<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.pangod-classify {
  font-size: 16px;
  background-color: #eeeef0;

  --frame-width: 1500px;

  &::before {
    content: "";
    display: table;
  }

  .navBar {
    background: #eeeef0;

    .pangod-classify-nav {
      width: var(--frame-width);
      margin: 0 auto;
      background-color: #fff;
      line-height: 60px;

      .pangod-classify-nav-ul {
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: center;

        .pangod-classify-nav-li {
          padding: 0 20px;
          text-align: center;
          cursor: pointer;
        }
      }
      .active {
        color: rgba(2, 69, 140, 0.7);
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 50%;
          height: 3px;
          background: rgba(2, 69, 140, 0.7);
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          z-index: 2;
          opacity: 1;
        }
      }
    }
  }

  .pangod-classify-content {
    width: var(--frame-width);
    margin: 0 auto 20px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #ffffff;

    .van-loading {
      text-align: center;
    }
    .h2-title {
      color: #91c81f;
      margin-top: 30px;
      text-align: center;
      font-size: 30px;
    }
    .certifications {
      display: flex;
      flex-wrap: wrap;
    }
    .up {
      width: 100%;
      height: 100%;
      overflow: auto;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 999;
      background-color: rgba($color: #000000, $alpha: 0.6);

      .close {
        position: absolute;
        right: 10px;
        top: 20px;
        z-index: 10;
        cursor: pointer;
      }
      .show {
        max-width: 80%;
        max-height: 80%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .certification {
      width: calc(25% - 22px);
      padding: 10px 0;
      margin: 20px 10px;
      transition: all 0.3s;

      .image {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
          cursor: pointer;
        }
      }
      .desc {
        text-align: center;
        margin-top: 5px;
      }
      .pangod-classify-listitem {
        width: 33.3%;
        font-size: 0.2rem;
        margin-right: 0;

        .image {
          height: 130px;
        }
      }
    }
  }
}
@media (max-width: 1600px) {
  .pangod-classify {
    --frame-width: 1200px;
  }
  .pangod-classify .pangod-classify-content {
    padding: pxToRem(10) pxToRem(20);
    box-sizing: border-box;

    .pangod-classify-html {
      width: 100%;

      table {
        max-width: 100% !important;
        height: auto !important;
        margin: 0 auto;

        tr {
          height: auto !important;

          td {
            height: auto !important;
            padding-left: 0 !important;
          }
        }
      }
    }
  }
  .pangod-classify-nav-li {
    font-size: 14px;
  }
}
@media (max-width: 1300px) {
  .pangod-classify {
    --frame-width: 1000px;

    .pangod-classify-content {
      .certification {
        width: 32%;
        margin: 0 5px;
      }
      .h2-title {
        font-size: 24px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .pangod-classify {
    --frame-width: 100%;
  }
  .pangod-classify .pangod-classify-content {
    box-sizing: border-box;
    margin-bottom: pxToRem(10);
  }

  .pangod-classify .navBar .pangod-classify-nav {
    line-height: 30px;

    .pangod-classify-nav-ul {
      justify-content: start;
      white-space: nowrap;
      overflow-x: scroll;
      padding: pxToRem(5) pxToRem(10);
    }
  }
}
@media (max-width: 750px) {
  .pangod-classify {
    .navBar .pangod-classify-nav {
      line-height: pxToRem(40);

      .pangod-classify-nav-ul {
        font-size: pxToRem(15);
      }
    }
    .pangod-classify-content {
      .certifications {
        justify-content: space-between;
      }
      .certification {
        width: 49%;
        margin: 0;

        .image {
          height: 120px;
        }
      }
      .up .close {
        width: 40px;
      }
      .h2-title {
        font-size: 18px;
        margin-top: 10px;
      }
    }
  }
}
</style>

// 联系我们 组件
<template>
  <div class="contact-box">
    <div class="top">
      <div class="title">联系我们</div>
    </div>
    <div class="bottom">
      <form action="" @submit.prevent="submit">
        <div class="form-item">
          <label for="">所在区域</label>
          <div class="value">
            <input
              name="area"
              v-model="params.address"
              autocomplete="off"
              type="text"
            />
          </div>
        </div>
        <div class="form-item">
          <label for=""
            >咨询内容
            <div class="tet">*</div></label
          >
          <div class="value">
            <input
              name="content"
              v-model="params.content"
              autocomplete="off"
              type="text"
            />
          </div>
        </div>
        <div class="form-item">
          <label for="">公司名称</label>
          <div class="value">
            <input
              name="call"
              v-model="params.title"
              autocomplete="off"
              type="text"
            />
          </div>
        </div>
        <div class="form-item">
          <label for=""
            >姓名
            <div class="tet">*</div></label
          >
          <div class="value">
            <input
              name="userName"
              v-model="params.userName"
              autocomplete="off"
              type="text"
            />
          </div>
        </div>
        <div class="form-item">
          <label for=""
            >联系电话
            <div class="tet">*</div></label
          >
          <div class="value">
            <input
              name="phone"
              v-model="params.phone"
              @input="checkPhone"
              autocomplete="off"
              type="text"
            />
          </div>
        </div>
        <div class="hint" :style="{ color: hintCol }">{{ hint }}</div>
        <div class="form-item">
          <el-button type="primary" native-type="submit">提 交</el-button>
          <div class="phone">咨询电话:&nbsp;&nbsp;400-6222-028</div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        phone: "",
        address: "",
        content: "",
        title: "",
        userName: "",
      },
      dictionaryCode: "",
      hint: "",
      hintCol: "#f00",
    };
  },
  props: ["parentCode"],
  watch: {
    parentCode(newer) {
      if (newer) {
        this.dictionaryCode = newer;
      }
    },
  },
  created() {
    this.dictionaryCode = this.parentCode;
  },
  mounted() {},
  methods: {
    submit() {
      let reg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      if (!this.params.content) {
        this.hint = "请填入您的咨询内容！";
        this.hintCol = "#f00";
      } else if (!reg.test(this.params.phone)) {
        this.hint = "请填入正确的手机号码！";
        this.hintCol = "#f00";
      } else {
        this.params.dictionaryCode = this.dictionaryCode;

        this.$api.addFeed(this.params).then((res) => {
          if (res.code == 0) {
            this.hint = "留言提交成功！我们将在收到消息后会尽快为您解答！";
            this.hintCol = "rgb(4 211 4)";

            Object.assign(this.$data.params, this.$options.data().params);
          } else {
            this.hint = res.msg;
            this.hintCol = "#f00";
          }

          setTimeout(() => {
            this.hint = "";
          }, 5000);
        });
      }
    },
    checkPhone(e) {
      let val = e.target.value;
      this.params.phone = val.replace(/[^\d]/g, "");
    },
  },
};
</script>

<style lang="scss" scoped>
@function pxToRem($args) {
  @return calc($args / 50) + rem;
}

.contact-box {
  width: 1000px;
  margin: 0 auto 70px;
  color: rgb(125, 126, 129);

  .top {
    display: flex;
    position: relative;
    .title {
      font-size: 27px;
      font-weight: 400;
      padding: 10px 0;
      position: relative;
      color: #000;

      &::after {
        content: "";
        display: block;
        width: 50%;
        height: 3px;
        background-color: $theme;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
    .ask {
      font-size: 18px;
      color: #fff;
      padding: 2px 10px;
      border-radius: 3px;
      background-color: $theme;
      position: absolute;
      left: 120px;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        cursor: pointer;
      }
    }
  }

  .bottom {
    form {
      width: 600px;
      margin: 50px auto 0;
      position: relative;

      .hint {
        position: absolute;
        bottom: 40px;
        left: 175px;
      }
      .form-item {
        width: 530px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        label {
          width: 80px;
          font-size: 20px;
          text-align: justify;
          text-justify: inter-ideograph;
          // text-justify: distribute-all-lines; // 兼容ie
          margin-right: 55px;
          color: #242b31;
          position: relative;

          &::after {
            content: "";
            width: 100%;
            display: inline-block;
            height: 0;
          }

          .tet {
            color: red;
            position: absolute;
            left: -10px;
            top: -3px;
          }
        }
        .value {
          flex-grow: 1;
          &::after {
            content: "";
            width: 100%;
            display: inline-block;
            height: 0;
          }
        }
        input[type="text"] {
          width: 100%;
          height: 40px;
          border-radius: 12px;
          border: 1px solid #eaebec;
          outline: none;
          transition: all 0.3s;
          text-indent: 10px;

          &:focus {
            border-color: $theme;
          }
        }

        input[type="submit"] {
          width: 80px;
          outline: none;
          border: none;
          font-size: 18px;
          border-radius: 10px;
          color: #fff;
          padding: 8px 20px;
          background-color: $theme;
          box-sizing: content-box;
          letter-spacing: 43px;

          &:hover {
            cursor: pointer;
          }
          &:active {
            transform: scale(0.9);
          }
        }
        .phone {
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 1px;
          position: absolute;
          right: 100px;
          color: $default;
        }

        &:last-child {
          width: 570px;
          margin-top: 30px;
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .contact-box {
    width: 100%;
  }
}
@media (max-width: 750px) {
  .contact-box {
    .top .title {
      font-size: 20px;
      font-weight: bold;
    }
    .bottom form {
      width: 100%;
      margin-top: 30px;

      .form-item {
        width: 100%;
      }
      .form-item {
        &:last-child {
          width: 100%;
        }
        label {
          width: pxToRem(70);
          font-size: pxToRem(16);
          margin-right: 20px;

          .tet {
            left: pxToRem(-10);
          }
        }
        input[type="text"] {
          height: pxToRem(26);
          font-size: pxToRem(12);
        }
        input[type="submit"] {
          width: auto;
          font-size: pxToRem(16);
          border-radius: 15px;
          letter-spacing: 20px;
        }
      }
    }
  }
  .hint {
    font-size: pxToRem(14);
    left: pxToRem(160) !important;
  }
  .phone {
    font-size: pxToRem(14) !important;
    right: 0 !important;
    bottom: 0;
  }
}
</style>

// 字典有三级或更多 组件
<template>
  <div class="threeMore">
    <div class="top">
      <div class="title">{{ list.name | handleShowName }}</div>
      <span class="ask" @click="toBottom">免费咨询</span>
    </div>
    <div class="center">{{ list.contextStrs }}</div>
    <div class="bottom">
      <ul
        :class="[
          'list',
          isMobile ? 'm_list' : '',
          children.length > 2 && isMobile ? 'scroll' : '',
        ]"
      >
        <li
          :class="['item', 'before', firstCode == item.code ? 'on' : '']"
          v-for="item in children"
          :key="item.id"
          @click="changeTab(item, 1)"
        >
          {{ item.name | handleShowName }}
        </li>
      </ul>
      <div class="list-desc">{{ ShowDesc }}</div>
      <ul
        :class="[
          'second-list',
          secondChild.length > 2 && isMobile ? 'scroll' : '',
          secondChild.length > 7 ? 'xscroll' : '',
        ]"
      >
        <li
          :class="['item', 'after', secondCode == item.code ? 'afterOn' : '']"
          v-for="item in secondChild"
          :key="item.id"
          @click="changeTab(item, 2)"
        >
          {{ item.name | handleShowName }}
        </li>
      </ul>
      <ul
        :class="[
          thirdChild.length > 7 ? 'thirdScroll' : 'third-list',
          thirdChild.length > 4 && isMobile ? 'scroll' : '',
        ]"
      >
        <li
          :class="['item', 'after', thirdCode == item.code ? 'thirdOn' : '']"
          v-for="item in thirdChild"
          :key="item.id"
          @click="changeTab(item, 3)"
        >
          {{ item.name | handleShowName }}
        </li>
      </ul>
      <div class="content">
        <div class="left" v-if="!isMobile" v-html="nowStr"></div>
        <div class="left" v-else v-html="nowMStr || nowStr"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "../../util/config";
import { Store } from "../../store";
import { mapState } from "pinia";
export default {
  data() {
    return {
      URL: URL,
      textarea: "",
      children: [],
      secondChild: [],
      thirdChild: [],
      firstCode: "",
      secondCode: "",
      thirdCode: "",
      nowStr: "",
      nowMStr: "", // 移动端样式
      nowImage: "",
      checkedArr: [],
      activeFirst: "",
    };
  },
  watch: {
    list(newval) {
      this.children = [...newval.children];
      this.activeFirst = this.children[0].code;
      this.setContent();
    },
  },
  props: {
    list: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(Store, ["isMobile", "navList"]),
    ShowDesc() {
      if (!this.firstCode) {
        return "";
      }
      let item = this.children.find((ele) => ele.code == this.firstCode);
      return item.contextStrs;
    },
  },
  created() {
    this.children = [...this.list.sysDictionaryNewVochildren];
    // 子集层级处理
    if (this.children.length > 0) {
      this.secondChild = [...this.children[0].sysDictionaryNewVochildren];
    }
    if (this.secondChild.length > 0) {
      this.thirdChild = [...this.secondChild[0].sysDictionaryNewVochildren];
    }
    this.activeFirst = this.children[0].code;
    this.setContent();
  },
  mounted() {},
  methods: {
    chooseFirst(val) {
      this.activeFirst = val.code;

      if (val.sysDictionaryNewVochildren.length == 0) {
        this.nowStr = val.sysNew[0].contentStr;
        this.nowMStr = val.sysNew[0].mobileContentStr;
      }
    },
    setContent() {
      /**
       * 默认展示
       * 先判断层级，若有三级则默认展示第一个一级字典的第一个三级的内容
       * 若无则判断二级字典
       * 若无则直接选择一级字典
       */
      this.firstCode = this.children[0].code;
      if (this.children.length > 0) {
        this.secondChild = this.children[0].sysDictionaryNewVochildren;

        if (this.secondChild.length > 0) {
          this.secondCode = this.secondChild[0].code;

          if (this.secondChild[0].sysDictionaryNewVochildren.length > 0) {
            this.thirdChild =
              this.children[0].sysDictionaryNewVochildren[0].sysDictionaryNewVochildren;
            this.thirdCode = this.thirdChild[0] && this.thirdChild[0].code;
          }
        }
      }

      let content = {};
      if (this.thirdChild.length != 0) {
        content = this.thirdChild[0];
      } else if (this.secondChild != 0) {
        content = this.secondChild[0];
      } else if (this.children != 0) {
        content = this.children[0];
      }

      this.changeShow(content);
    },
    dropDownCallback(val) {
      this.checkedArr = [];
      val.forEach((ele) => {
        this.checkedArr.push(ele.code);
      });

      let content;
      content = val[val.length == 1 ? 0 : 1].sysNew[0];
      this.nowStr = content.contentStr;
      this.nowMStr = content.mobileContentStr;
      this.nowImage = content.newsImageUrl;
    },
    // 滚动至底部
    toBottom() {
      this.$emit("scrollTo");
    },
    changeTab(item, level) {
      // level 1、2、3 代表三层字典等级的点击
      switch (level) {
        case 1:
          // 点击一级字典
          this.firstCode = item.code;
          if (item.sysDictionaryNewVochildren.length > 0) {
            this.secondChild = item.sysDictionaryNewVochildren;
            this.secondCode = this.secondChild[0].code;

            if (this.secondChild[0].sysDictionaryNewVochildren.length > 0) {
              this.thirdChild = this.secondChild[0].sysDictionaryNewVochildren;
              this.thirdCode = this.thirdChild[0].code;
              this.changeShow(this.thirdChild[0]);
            } else {
              this.thirdChild = [];
              this.changeShow(this.secondChild[0]);
            }
          } else {
            this.secondChild = [];
            this.thirdChild = [];
            this.changeShow(item);
          }
          break;
        case 2:
          // 点击二级字典
          this.secondCode = item.code;
          if (item.sysDictionaryNewVochildren.length > 0) {
            this.thirdChild = item.sysDictionaryNewVochildren;
            this.thirdCode = this.thirdChild[0] && this.thirdChild[0].code;
            this.changeShow(this.thirdChild[0]);
          } else {
            this.thirdChild = [];
            this.changeShow(item);
          }
          break;
        case 3:
          // 点击三级字典
          this.thirdCode = item.code;
          this.changeShow(item);
          break;

        default:
          break;
      }
    },
    // 改变展示内容事件
    changeShow(item) {
      if (item.sysNew) {
        this.nowStr = item.sysNew[0].contentStr;
        this.nowMStr = item.sysNew[0].mobileContentStr;
        this.nowImage = item.sysNew[0].newsImageUrl;
      } else {
        this.nowStr = "";
        this.nowMStr = "";
        this.nowImage = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.threeMore {
  width: 1000px;
  margin: 0 auto 70px;
  color: rgb(125, 126, 129);

  .top {
    width: fit-content;
    position: relative;
    .title {
      font-size: 27px;
      font-weight: 400;
      padding: 10px 0;
      position: relative;
      color: #000;

      &::after {
        content: "";
        display: block;
        width: 50%;
        height: 3px;
        background-color: $theme;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
    .ask {
      white-space: nowrap;
      font-size: 18px;
      color: #fff;
      padding: 2px 10px;
      border-radius: 3px;
      background-color: $theme;
      position: absolute;
      left: 120%;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        cursor: pointer;
      }
    }
  }

  .center {
    margin: 20px 0;
    text-align: center;
  }

  .bottom {
    margin-top: 40px;

    .list,
    .second-list,
    .third-list {
      padding: 10px 0;
      display: flex;
      justify-content: space-around;
      overflow-x: auto;
      overflow-y: hidden;

      .item {
        min-width: 132px;
        font-size: 15px;
        color: #68779c;
        text-align: center;
        white-space: nowrap;
        flex-shrink: 0;
      }
      .before {
        border: 2px solid rgba(44, 114, 255, 0.1);
        border-radius: 10px;
        padding: 8px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:hover {
          cursor: pointer;
          border-color: $theme;
        }
        &::before,
        &::after {
          content: "";
          border-style: solid;
          position: absolute;
          bottom: -16px;
          left: 50%;
          transform: translateX(-50%);
          display: none;
        }
        &::before {
          border-width: 8px;
          border-color: $theme transparent transparent transparent;
        }
        &::after {
          border-width: 11px;
          border-color: #fff transparent transparent transparent;
        }
      }
      .after {
        padding: 5px 8px;
        box-sizing: border-box;

        &:hover {
          cursor: pointer;
          color: $theme;
        }
      }

      .on {
        color: #444;
        border-color: $theme;
        font-weight: 600;
        &::before,
        &::after {
          display: block;
        }
      }
    }
    .second-list {
      .item {
        border: 2px solid #68779c;
        border-radius: 10px;

        &:hover {
          border-color: $theme;
        }
      }
      .afterOn {
        color: $theme;
        border: 2px solid $theme;
        font-weight: 600;
      }
    }
    .third-list,
    .thirdScroll {
      .thirdOn {
        color: $theme;
        border-bottom: 2px solid $theme;
        font-weight: 600;
      }
    }
    .second-list,
    .thirdScroll {
      &::-webkit-scrollbar {
        height: 0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba($color: #000000, $alpha: 0.2);
        border-radius: 10px;
        -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba($color: #000000, $alpha: 0.4);
        -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
      }
      &:hover:-webkit-scrollbar-thumb {
        background-color: rgba($color: #000000, $alpha: 0.2);
        border-radius: 10px;
        -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      }
      &::-webkit-scrollbar-track:hover {
        background-color: rgba($color: #000000, $alpha: 0.01);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
      }
      &:hover {
        &::-webkit-scrollbar {
          height: 5px;
        }
      }
    }
    .xscroll {
      justify-content: start;
      flex-shrink: 0;
    }
    .list-desc {
      width: 80%;
      margin: 0 auto;
      padding: 18px 0;
      font-size: 14px;
      color: #68779c;
      text-align: center;
      white-space: pre-wrap;

      &:empty {
        display: none;
      }
    }
    .m_list {
      // h60
      .item {
        font-size: 12px;
        margin-right: 5px;
      }
    }
    .scroll {
      justify-content: start;

      .item {
        min-width: 100px;
        flex-shrink: 0;
      }
    }
    .third-list {
      padding: 0;
      margin: 10px 0;
      border-bottom: 1px solid rgba(44, 114, 255, 0.1);
      overflow-x: auto;
    }
    .thirdScroll {
      width: 100%;
      overflow-x: auto;
      margin: 10px 0;
      display: flex;
      flex-wrap: nowrap;

      .item {
        width: 132px;
        font-size: 15px;
        color: #68779c;
        text-align: center;
        flex-shrink: 0;
        padding: 5px 0;
        &:hover {
          cursor: pointer;
          color: $theme;
        }
      }
      .thirdOn {
        color: $theme;
        font-weight: 600;
      }
    }
    .content {
      color: $default;
      display: flex;
      align-items: center;

      .left {
        width: 100%;
        padding: 30px;
        line-height: 30px;
        font-size: 18px;
        flex-shrink: 0;
        box-sizing: border-box;
        letter-spacing: 0.5px;
        text-align: justify;

        ul {
          list-style-type: disc;
          list-style-position: inside;
        }
        ol {
          list-style-type: decimal;
          list-style-position: inside;
        }
      }
    }
  }
}
@media (max-width: 1300px) {
  .threeMore {
    width: 100%;
  }
}
@media (max-width: 750px) {
  .threeMore {
    margin-bottom: 20px;

    .top {
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .ask {
        font-size: 16px;
      }
    }
    .bottom {
      margin-top: 20px;

      .desc {
        padding-top: 20px;
      }
      .list-desc {
        width: 95%;
        padding: 10px 0;
        text-align: justify;
      }
      .second-list .item + .item {
        margin-left: 10px;
      }
      .content .left {
        padding: 10px 0;
      }
    }
  }
}
</style>
